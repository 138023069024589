import React, { useState, useRef, useEffect, useMemo, forwardRef, useImperativeHandle } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Select from 'react-select';
import { FaGripVertical, FaTrashAlt, FaEye } from 'react-icons/fa';

const ScreenerQuestionsBottomSheetStep = ({
  showScreenerSheet,
  setShowScreenerSheet,
  screenerQuestions,
  setScreenerQuestions,
  handleSaveScreenerQuestions,
  addNewQuestion,
  handleQuestionTextChange,
  handleQuestionTypeChange,
  handleDeleteScreenerQuestion,
  handleAddOption,
  handleRemoveOption,
  handleOptionChange,
  handleOptionActionChange,
  handleAnswerTextChange,
  handleToggleRequired,
  questionTypeOptions,
  multipleChoiceActionOptions,
  singleChoiceActionOptions,
  handleScreenerDragEnd,
  handleScreenerQuestionUpdate,
  handleToggleSkipLogic,
  handleToggleAdvancedSkipLogic,
  handleSkipToChange,
  handleAdvancedRuleChange,
  handleAddAdvancedRule,
  handleRemoveAdvancedRule
}) => {

  const buildSkipOptions = (currentQuestionId) => {
    const skipOptions = [
      { value: 'finish', label: 'Finish' },
      ...screenerQuestions
        .filter(q => q.id !== currentQuestionId)
        .map(q => ({ value: q.id, label: q.questionText || `Question: ${q.id}` }))
    ];
    return skipOptions;
  };

  const [showPreview, setShowPreview] = useState(false);

  const [previewState, setPreviewState] = useState({
    isOpen: false,
    currentQuestionIndex: 0,
    answers: {}, // Store user answers as { questionId: selectedOptionIndexes }
  });

  const renderPreview = () => {
    const { isOpen, currentQuestionIndex, answers } = previewState;
  
    if (!isOpen || currentQuestionIndex >= screenerQuestions.length) return null;
  
    const currentQuestion = screenerQuestions[currentQuestionIndex];
  
    const handleAnswerChange = (optionIndex) => {
      const newAnswers = { ...answers };
      if (currentQuestion.questionType === 'multiple') {
        const currentSelections = newAnswers[currentQuestion.id] || [];
        if (currentSelections.includes(optionIndex)) {
          newAnswers[currentQuestion.id] = currentSelections.filter(idx => idx !== optionIndex);
        } else {
          newAnswers[currentQuestion.id] = [...currentSelections, optionIndex];
        }
      } else {
        newAnswers[currentQuestion.id] = [optionIndex];
      }
      setPreviewState({ ...previewState, answers: newAnswers });
    };
  
    const handleNext = () => {
      const selectedOptions = answers[currentQuestion.id] || [];
  
      // Handle Skip Logic
      if (currentQuestion.skip_logic && !currentQuestion.advanced_skip_logic) {
        const selectedOption = currentQuestion.options[selectedOptions[0]];
        if (selectedOption?.skip_to) {
          if (selectedOption.skip_to === 'finish') {
            setPreviewState({ ...previewState, isOpen: false });
            return;
          }
          const nextIndex = screenerQuestions.findIndex(q => q.id === selectedOption.skip_to);
          if (nextIndex !== -1) {
            setPreviewState({ ...previewState, currentQuestionIndex: nextIndex });
            return;
          }
        }
      }
  
      // Handle Advanced Skip Logic (for multiple-choice questions)
      if (currentQuestion.advanced_skip_logic && currentQuestion.logicRules?.length > 0) {
        for (const rule of currentQuestion.logicRules) {
          const ruleMatches = rule.answersSelected.every(opt =>
            selectedOptions.includes(parseInt(opt.split('-')[1], 10))
          );
          if (ruleMatches && rule.skipTo) {
            if (rule.skipTo === 'finish') {
              setPreviewState({ ...previewState, isOpen: false });
              return;
            }
            const nextIndex = screenerQuestions.findIndex(q => q.id === rule.skipTo);
            if (nextIndex !== -1) {
              setPreviewState({ ...previewState, currentQuestionIndex: nextIndex });
              return;
            }
          }
        }
      }
  
      // Default: Move to next question
      setPreviewState({ ...previewState, currentQuestionIndex: currentQuestionIndex + 1 });
    };
  
    const handleBack = () => {
      if (currentQuestionIndex > 0) {
        setPreviewState({ ...previewState, currentQuestionIndex: currentQuestionIndex - 1 });
      }
    };
  
    const isNextDisabled = currentQuestion.required && (!answers[currentQuestion.id] || answers[currentQuestion.id].length === 0);
  
    return (
      <div className="preview-modal">
        <div className="preview-content">
          <div className="preview-header">
            <h3>Screener Survey Preview</h3>
            <button className="close-preview" onClick={() => setPreviewState({ ...previewState, isOpen: false })}>
              ×
            </button>
          </div>
          <div className="preview-body">
            {currentQuestionIndex < screenerQuestions.length ? (
              <div className="preview-question">
                <h4>
                  {`${currentQuestionIndex + 1}. ${currentQuestion.questionText || 'Untitled Question'}`}
                  {currentQuestion.required && <span className="required-star">*</span>}
                </h4>
                {currentQuestion.questionType === 'single' || currentQuestion.questionType === 'multiple' ? (
                  <div className="preview-options">
                    {currentQuestion.options.map((opt, optIdx) => (
                      <label key={optIdx} className="preview-option">
                        <input
                          type={currentQuestion.questionType === 'multiple' ? 'checkbox' : 'radio'}
                          name={currentQuestion.id}
                          checked={(answers[currentQuestion.id] || []).includes(optIdx)}
                          onChange={() => handleAnswerChange(optIdx)}
                        />
                        <span>{opt.text || `Option ${optIdx + 1}`}</span>
                      </label>
                    ))}
                  </div>
                ) : currentQuestion.questionType === 'short' ? (
                  <input
                    type="text"
                    value={answers[currentQuestion.id]?.[0] || ''}
                    onChange={(e) => setPreviewState({
                      ...previewState,
                      answers: { ...answers, [currentQuestion.id]: [e.target.value] }
                    })}
                    placeholder="Short Answer"
                  />
                ) : (
                  <textarea
                    value={answers[currentQuestion.id]?.[0] || ''}
                    onChange={(e) => setPreviewState({
                      ...previewState,
                      answers: { ...answers, [currentQuestion.id]: [e.target.value] }
                    })}
                    placeholder="Long Answer"
                  />
                )}
                <div className="preview-controls">
                  <button
                    className="preview-back-button"
                    onClick={handleBack}
                    disabled={currentQuestionIndex === 0}
                  >
                    Back
                  </button>
                  <button
                    className="preview-next-button"
                    onClick={handleNext}
                    disabled={isNextDisabled}
                  >
                    Next
                  </button>
                </div>
              </div>
            ) : (
              <div className="preview-end">
                <h4>Survey Completed</h4>
                <button
                  className="preview-close-button"
                  onClick={() => setPreviewState({ ...previewState, isOpen: false })}
                >
                  Close
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };


  return (
    <div className={`bottom-sheet-screener ${showScreenerSheet ? 'show' : ''}`}>
      {showScreenerSheet && (
        <>
          <div className="bottom-sheet-header">
            <h3>Add Screener Questions</h3>
            <div className="bottom-sheet-actions">
              <button
                    className="preview-button"
                    onClick={() => setPreviewState({ isOpen: true, currentQuestionIndex: 0, answers: {} })}
                  >
                    <FaEye /> Preview
                  </button>
              <button
                className="button-container-back-button"
                onClick={() => setShowScreenerSheet(false)}
              >
                Cancel
              </button>
              <button className="continue-button" onClick={handleSaveScreenerQuestions}>
                Save
              </button>
            </div>
          </div>
          <hr className="screener-line" />

        <div className='screener-container'>
          <DragDropContext onDragEnd={handleScreenerDragEnd}>
            <Droppable droppableId="screenerQuestions">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {screenerQuestions.map((q, index) => (
                    <Draggable key={q.id} draggableId={q.id} index={index}>
                      {(provided) => (
                        <div
                          className="screener-question"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          {/* Drag handle */}
                          <div className="drag-handle" {...provided.dragHandleProps}>
                            <FaGripVertical />
                          </div>

                          {/* Top row: question text, type, delete */}
                          <div className="question-row">
                            <input
                              type="text"
                              value={q.questionText}
                              onChange={(e) => handleQuestionTextChange(q.id, e.target.value)}
                              placeholder={`Enter question ${index + 1}`}
                            />
                            <Select
                              value={questionTypeOptions.find(opt => opt.value === q.questionType)}
                              onChange={(selectedOption) => handleQuestionTypeChange(q.id, selectedOption.value)}
                              options={questionTypeOptions}
                              className="question-type-select"
                            />
                            <button
                              className="delete-button-screener-question"
                              onClick={() => handleDeleteScreenerQuestion(q.id)}
                            >
                              <FaTrashAlt />
                            </button>
                          </div>

                          {/* Second row: required & skip logic toggles together */}
                          {(q.questionType === 'single' || q.questionType === 'multiple') ? (
                            <div className="question-footer">
                              <label className="required-label">
                                <input
                                  type="checkbox"
                                  checked={q.required}
                                  onChange={() => handleToggleRequired(q.id)}
                                />
                                Required
                              </label>
                              <div className="skip-logic-section">
                                <label className="skip-logic-toggle">
                                  <input
                                    type="checkbox"
                                    checked={q.skip_logic || false}
                                    onChange={() => handleToggleSkipLogic(q.id)}
                                  />
                                  Enable Skip Logic
                                </label>
                                {/* For multi-select, show advanced toggle if skip logic is on */}
                                {q.questionType === 'multiple' && q.skip_logic && (
                                  <label className="advanced-toggle">
                                    <input
                                      type="checkbox"
                                      checked={q.advanced_skip_logic || false}
                                      onChange={() => handleToggleAdvancedSkipLogic(q.id)}
                                    />
                                    Advanced
                                  </label>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="question-footer">
                              <label className="required-label">
                                <input
                                  type="checkbox"
                                  checked={q.required}
                                  onChange={() => handleToggleRequired(q.id)}
                                />
                                Required
                              </label>
                            </div>
                          )}

                          {/* Render question’s answer area */}
                          {q.questionType === 'single' || q.questionType === 'multiple' ? (
                            <div className="options-container">
                              {q.options.map((option, optionIndex) => (
                                <div key={optionIndex} className="option-input">
                                  <input
                                    type={q.questionType === 'multiple' ? 'checkbox' : 'radio'}
                                    disabled
                                  />
                                  <input
                                    type="text"
                                    value={option.text}
                                    onChange={(e) => handleOptionChange(q.id, optionIndex, e.target.value)}
                                    placeholder={`Option ${optionIndex + 1}`}
                                  />
                                  <Select
                                    className="option-action-select"
                                    value={q.questionType === 'multiple'
                                      ? multipleChoiceActionOptions.find(opt => opt.value === option.action)
                                      : singleChoiceActionOptions.find(opt => opt.value === option.action)
                                    }
                                    onChange={(selectedOption) => handleOptionActionChange(q.id, optionIndex, selectedOption.value)}
                                    options={q.questionType === 'multiple' ? multipleChoiceActionOptions : singleChoiceActionOptions}

                                  />


                                  {/* For simple skip logic (non-advanced), show skip dropdown */}
                                  {q.skip_logic && !q.advanced_skip_logic && (
                                    <Select
                                      className="skip-to-dropdown"
                                      placeholder="Skip to..."
                                      value={
                                        option.skip_to
                                          ? buildSkipOptions(q.id).find(
                                              opt => opt.value === option.skip_to
                                            )
                                          : null
                                      }
                                      onChange={(selected) =>
                                        handleSkipToChange(q.id, optionIndex, selected?.value || '')
                                      }
                                      options={buildSkipOptions(q.id)}
                                      isClearable
                                    />
                                  )}

                                <button
                                    className="add-option-button"
                                    onClick={() => handleAddOption(q.id, optionIndex)}
                                  >
                                    +
                                  </button>
                                  {optionIndex >= 2 && (
                                    <button
                                      className="remove-option-button"
                                      onClick={() => handleRemoveOption(q.id, optionIndex)}
                                      disabled={
                                        q.questionType === 'multiple'
                                          ? q.options.length <= 1
                                          : q.options.length <= 1
                                      }
                                    >
                                      -
                                    </button>
                                  )}
                                </div>
                              ))}
                            </div>
                          ) : q.questionType === 'short' ? (
                            <input
                              type="text"
                              placeholder="Short Answer"
                              value={q.answerText || ''}
                              onChange={(e) => handleAnswerTextChange(q.id, e.target.value)}
                              className="short-answer-placeholder"
                            />
                          ) : (
                            <textarea
                              placeholder="Long Answer"
                              value={q.answerText || ''}
                              onChange={(e) => handleAnswerTextChange(q.id, e.target.value)}
                              className="long-answer-placeholder"
                            />
                          )}

                          {/* Advanced Skip Logic UI (only for multiple & skip_logic enabled) */}
                          {q.questionType === 'multiple' && q.skip_logic && q.advanced_skip_logic && (
                            <div className="advanced-skip-logic-container">
                              <h4>Advanced Skip Logic</h4>
                              <p className="help-text">
                                Use rules to define exactly when participants are skipped to a different question or finish.
                              </p>
                              {(q.logicRules || []).map((rule, ruleIndex) => (
                                <div key={ruleIndex} className="advanced-rule">
                                  <div className="rule-header">
                                    <span>Rule {ruleIndex + 1}</span>
                                    <button
                                      className="remove-rule-button"
                                      onClick={() => handleRemoveAdvancedRule(q.id, ruleIndex)}
                                    >
                                      Remove Rule
                                    </button>
                                  </div>
                                  <div className="rule-body">
                            
                                    <p>If the participant selects these answer(s):</p>
                                    <div className="rule-answers">
                                     
                                      <Select
                                        isMulti
                                        options={q.options.map((opt, idx) => ({
                                          value: `option-${idx}`,
                                          label: opt.text || `Option ${idx + 1}`
                                        }))}
                                        value={(rule.answersSelected || []).map(val => {
                                          // match by value
                                          return {
                                            value: val,
                                            label:
                                              q.options[parseInt(val.split('-')[1], 10)]?.text ||
                                              val
                                          };
                                        })}
                                        onChange={(selectedOptions) => {
                                          const newValues = selectedOptions.map(o => o.value);
                                          handleAdvancedRuleChange(
                                            q.id,
                                            ruleIndex,
                                            'answersSelected',
                                            newValues
                                          );
                                        }}
                                        placeholder="Select answers..."
                                      />
                                    </div>
                                    <p>Then skip to:</p>
                                    <Select
                                      className="skip-to-dropdown"
                                      placeholder="Skip to..."
                                      value={
                                        rule.skipTo
                                          ? buildSkipOptions(q.id).find(opt => opt.value === rule.skipTo)
                                          : null
                                      }
                                      onChange={(selected) => {
                                        handleAdvancedRuleChange(
                                          q.id,
                                          ruleIndex,
                                          'skipTo',
                                          selected?.value || ''
                                        );
                                      }}
                                      options={buildSkipOptions(q.id)}
                                      isClearable
                                    />
                                  </div>
                                </div>
                              ))}
                              <button
                                className="add-advanced-rule-button"
                                onClick={() => handleAddAdvancedRule(q.id)}
                              >
                                Add New Rule
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <button className="add-question-button-screener" onClick={() => addNewQuestion('multiple')}>
            Add Question
          </button>
          </div>
          {previewState.isOpen && renderPreview()}
        </>
      )}
    </div>
  );
};

export default ScreenerQuestionsBottomSheetStep;
