import React, { useState, useEffect } from 'react';
import { addNewAttribute, fetchAttributes } from '../ApiUtils'; 
import { useAuth0 } from '@auth0/auth0-react';

import Select from 'react-select';
import { LANGUAGE_OPTIONS, customSelectStyles } from '../languageConfig';

const ObjectiveStep = ({ attributeIds, setAttributeIds,  attributes, setAttributes, selectedBlock, researchObjective, setResearchObjective, validationError ,conversationLanguage, setConversationLanguage}) => {
  const [newAttributeLabel, setNewAttributeLabel] = useState('');
  const [newAttributeDescription, setNewAttributeDescription] = useState('');
  const [newAttributeError, setNewAttributeError] = useState('');
  const [isLoadingAttributes, setIsLoadingAttributes] = useState(false);

  const [fetchError, setFetchError] = useState(null);
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Spanish' },
    { value: 'fr', label: 'French' },
    { value: 'de', label: 'German' },
    // Add more languages as needed
  ];


  
  const getExampleText = () => {
    switch (selectedBlock) {
      case 1:
        return `Example: "To evaluate the effectiveness and user acceptance of a new AI-powered personal finance app among millennials (ages 25-40) in urban areas, with the goal of improving financial literacy and savings habits. This research aims to understand users' current financial management practices, assess the app's key features such as automated budgeting and investment recommendations, identify potential barriers to adoption, and gauge willingness to pay for premium features. The insights gathered will inform product development, marketing strategies, and potential partnerships with financial institutions, ultimately guiding the app's launch and growth strategy in the competitive fintech market."`;
      case 3:
        return `Example: "To evaluate the appeal, emotional resonance, and effectiveness of a new advertising campaign concept for a premium coffee brand targeting urban millennials (ages 25-40) who value artisanal quality and sustainable practices. This research seeks to understand how the creative elements—such as visuals, messaging, tone, and storytelling—align with the target audience's values and aspirations. It will also identify potential areas of confusion, unintended interpretations, or lack of engagement. Insights from this research will inform refinements to the creative concept, ensuring it captures attention, drives brand affinity, and motivates trial and purchase within the competitive specialty coffee market."`;
      case 4:

        return `Example: "We want to gather detailed feedback from our current users on their experiences with our productivity app, FocusFlow, to understand satisfaction with key features such as task management, time tracking, and collaboration tools. The objective is to identify which attributes of the app users find most valuable, pinpoint any frustrations or usability challenges, and assess overall perceptions of reliability, design, and ease of use. This research will provide actionable insights to refine existing features, enhance user engagement, and inform the development of new functionalities to better meet user needs and expectations."`;
      case 5:
        return `Example: "To transcribe and analyze customer interview recordings to uncover key themes, sentiments, and actionable insights regarding user experiences with our new home automation system. This research aims to identify recurring pain points, areas of delight, and unmet needs expressed during the interviews. The transcription process will ensure accuracy and comprehensive analysis, while the insights will inform product enhancements, customer support strategies, and feature prioritization. Ultimately, the findings will help us align our offerings with user expectations and strengthen our value proposition in the competitive smart home market"`;

      default:
        return 'Please add a detailed research objective. This will help us create a research questions guide.';
    }
  };

  const handleCheck = (index) => {
    const updatedAttributes = [...attributes];
    updatedAttributes[index].checked = !updatedAttributes[index].checked;
    setAttributes(updatedAttributes);
  
    setAttributeIds((prevAttributeIds) => {
      const safePrevAttributeIds = Array.isArray(prevAttributeIds) ? prevAttributeIds : []; 
      if (updatedAttributes[index].checked) {
        return [...safePrevAttributeIds, updatedAttributes[index].id];
      } else {
        return safePrevAttributeIds.filter((id) => id !== updatedAttributes[index].id);
      }
    });
  
  };
  
  
  const handleAddAttribute = async () => {
    if (newAttributeLabel.trim() === '' || newAttributeDescription.trim() === '') {
      setNewAttributeError('Both label and description are required.');
      return;
    }

    try {
      const response = await addNewAttribute({
        label: newAttributeLabel.trim(),
        description: newAttributeDescription.trim(),
        getAccessTokenSilently,
        getIdTokenClaims,
      });

      const updatedAttributes = [
        ...attributes,
        {
          label: newAttributeLabel.trim(),
          description: newAttributeDescription.trim(),
          checked: true,
          id: response.attribute_id, 
        },
      ];
      setAttributes(updatedAttributes);
      setNewAttributeLabel('');
      setNewAttributeDescription('');
      setNewAttributeError('');
    } catch (error) {
      console.error('Failed to add new attribute:', error);
      setNewAttributeError('Failed to add attribute. Please try again.');
    }
  };


  useEffect(() => {
  
    const loadAttributes = async () => {
     

      if (!attributes || attributes.length === 0) {
  
      if (selectedBlock === 3 ) {
        setIsLoadingAttributes(true);
        setFetchError(null);
        try {
          const fetchedAttributes = await fetchAttributes({ getAccessTokenSilently, getIdTokenClaims });
        
    
          const formattedAttributes = fetchedAttributes.map(attr => ({
            label: attr.attribute_name,
            description: attr.description || '',
            checked: (attributeIds || []).includes(attr.id), 
            id: attr.id,
          }));
          
          setAttributes(formattedAttributes);
        } catch (error) {
          console.error("Failed to load attributes:", error);
          setFetchError('Failed to load attributes. Please try again.');
        } finally {
          setIsLoadingAttributes(false);
        }
      }
    }
    else {
      setAttributes(attributes);
    }
    
    };

    loadAttributes();
  }, [ selectedBlock, getAccessTokenSilently, getIdTokenClaims]);
  


  return (
    <div className="objective-step">
      <div className="question-block">
        <div className="step-label">{selectedBlock == 5? 'Add analysis objective': 'Add research objective'}</div>
        <p className="example-text">{getExampleText()}</p>
        <textarea
          id="researchObjective"
          value={researchObjective}
          onChange={(e) => setResearchObjective(e.target.value)}
        />
        {validationError && (
          <div className="err-message">{validationError}</div>
        )}
      </div>

      <div className="language-picker-container">
        <div className="language-picker-label">Select Conversation Language</div>
        <Select
          className="language-select"
          styles={customSelectStyles}
          options={LANGUAGE_OPTIONS}
          value={
            LANGUAGE_OPTIONS.find((opt) => opt.value === conversationLanguage) ||
            LANGUAGE_OPTIONS.find((opt) => opt.value === 'en') // Default to English
          }
          onChange={(selectedOption) => setConversationLanguage(selectedOption.value)}
          placeholder="-- Select Language --"
        />
      </div>

      {selectedBlock === 3 && (
        <div className="question-block attributes-block">
          <div className="step-label">Attributes to be tested</div>
          <div className="attributes-grid">
            {attributes.map((attribute, index) => (
              <div key={index} className="attribute-item"
              onClick={() => handleCheck(index)}
              >
                <input
                  type="checkbox"
                  checked={attribute.checked}
                  
                />
                <span className="attribute-label">{attribute.label}</span>
                <span className="attribute-description">{attribute.description}</span> {/* Add description */}
              </div>

            ))}
          </div>

          <div className="add-new-attribute">
            <div className="new-attribute-input-container">
            <div className="add-new-attribute-header">Add new attribute</div>
              <input
                className="new-attribute-input-label"
                type="text"
                placeholder="Give a name for the attribute. Ex: Healthy"
                value={newAttributeLabel}
                onChange={(e) => setNewAttributeLabel(e.target.value)}
              />
              <input
                className="new-attribute-input-description"
                type="text"
                placeholder="Give a description. Ex: Measures how healthy the product is perceived by the audience"
                value={newAttributeDescription}
                onChange={(e) => setNewAttributeDescription(e.target.value)}
              />
            </div>

            <button
              className="add-new-attribute-button"
              onClick={handleAddAttribute}
            >
              Add 
            </button>
          </div>
          {newAttributeError && (
              <div className="err-message">{newAttributeError}</div>
            )}
        </div>
      )}


    </div>
  );
};

export default ObjectiveStep;
