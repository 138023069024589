import React, { useState, useRef, useEffect } from "react";
import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import AffinityTheme from "./AffinityTheme";
import "./AffinityCanvas.css";
import { FaPlus, FaMousePointer, FaDownload, FaSearchPlus, FaSearchMinus } from "react-icons/fa";
import AffinityQuote from "./AffinityQuote";
import html2canvas from "html2canvas";

const AffinityCanvas = ({ initialWidth = "80vw", initialHeight = "800px", codes, topicsQuestionsAnalysis }) => {
  const canvasContentRef = useRef(null);
  const [quotes, setQuotes] = useState([]);
  const [themes, setThemes] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const canvasScrollRef = useRef(null);
  const toggleFullScreen = () => setIsFullScreen(!isFullScreen);
  const canvasRef = useRef(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const quoteIdCounter = useRef(0);
  const [activeTool, setActiveTool] = useState("select");
  const [themeHeights, setThemeHeights] = useState({});
  const themeHeightsRef = useRef({});
  const themesPerRowRef = useRef(3); // Default


  const updateThemePosition = (id, left, top) => {
    setThemes((prevThemes) =>
      prevThemes.map((theme) =>
        theme.id === id ? { ...theme, left, top, isDragged: theme.isDragged || false } : theme
      )
    );
  };
  
  const handleSetThemeHeight = (themeId, height) => {
    themeHeightsRef.current[themeId] = height;
    setThemeHeights({ ...themeHeightsRef.current });
  };

  const [{ isOverCanvas, canDropCanvas }, drop] = useDrop(() => ({
    accept: "QUOTE",
    drop: (item, monitor) => {
      if (monitor.didDrop()) return;
      return { themeId: null };
    },
    collect: (monitor) => ({
      isOverCanvas: monitor.isOver({ shallow: true }),
      canDropCanvas: !!monitor.canDrop(),
    }),
  }));

  const moveTheme = (id, left, top) => {
    setThemes((prevThemes) =>
      prevThemes.map((theme) =>
        theme.id === id ? { ...theme, left, top, isDragged: true } : theme
      )
    );
  };



  const removeTheme = (id) => setThemes(themes.filter((theme) => theme.id !== id));
  const updateThemeTitle = (id, title) => {
    setThemes(themes.map((theme) => (theme.id === id ? { ...theme, title } : theme)));
  };



  const handleZoomIn = () => setZoomLevel(prev => Math.min(prev + 0.1, 2));
  const handleZoomOut = () => setZoomLevel(prev => Math.max(prev - 0.1, 0.5));
  const handleDownload = () => {
    html2canvas(canvasRef.current).then((canvas) => {
      const link = document.createElement("a");
      link.download = "affinity-map.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  useEffect(() => {
    if (topicsQuestionsAnalysis) {
      console.log("inside topicsQuestionsAnalysis flow");
      setThemes([]);
      setQuotes([]);
      quoteIdCounter.current = 1;

      themesPerRowRef.current = 3;
      const themeWidth = 400;
      const defaultThemeHeight = 300;
      let themeCounter = 1;
      let questionIndex = 0;

      let newThemes = [];
      let newQuotes = [];
      const canvasScroll = canvasScrollRef.current;
      const baseLeft = canvasScroll ? canvasScroll.clientWidth / 2 + 1500 : 1500;
      const baseTop = canvasScroll ? canvasScroll.clientHeight / 2 + 1500 : 1500;
      const marginX = 20;
      const marginY = 20;

      Object.entries(topicsQuestionsAnalysis).forEach(([topicKey, topicVal]) => {
        if (!topicVal.questions) return;

        topicVal.questions.forEach((question) => {
          const { question_text, themes: questionThemes } = question;
          const rowIndex = Math.floor(questionIndex / themesPerRowRef.current);
          const colIndex = questionIndex % themesPerRowRef.current;
          const left = baseLeft + colIndex * (themeWidth + marginX);
          const top = baseTop + rowIndex * (defaultThemeHeight + marginY);

          const newTheme = { id: themeCounter, title: question_text, left, top, isDragged: false };
          newThemes.push(newTheme);

          if (Array.isArray(questionThemes)) {
            questionThemes.forEach((subTheme, i) => {
              newQuotes.push({
                id: quoteIdCounter.current++,
                content: subTheme.theme,
                themeId: themeCounter,
                columnIndex: 0,
                order: i,
              });
            });
          }

          questionIndex++;
          themeCounter++;
        });
      });

      setThemes(newThemes);
      setQuotes(newQuotes);
    } else if (codes && (codes.summaries || codes.codes)) {
      themesPerRowRef.current = 4;
      const themeWidth = 400;
      const defaultThemeHeight = 600;
      const marginX = 20;
      const marginY = 20;

      let final_codes = codes.summaries || codes.codes;
      const canvasScroll = canvasScrollRef.current;
      const baseLeft = canvasScroll ? canvasScroll.clientWidth / 2 + 1500 : 1500;
      const baseTop = canvasScroll ? canvasScroll.clientHeight / 2 + 1500 : 1500;

      const newThemes = final_codes.map((codeItem, index) => {
        const rowIndex = Math.floor(index / themesPerRowRef.current);
        const colIndex = index % themesPerRowRef.current;
        const left = baseLeft + colIndex * (themeWidth + marginX);
        const top = baseTop + rowIndex * (defaultThemeHeight + marginY);

        return { id: index + 1, title: codeItem.summary || codeItem.code, left, top, isDragged: false };
      });

      let quoteCounter = 1;
      const newQuotes = [];
      final_codes.forEach((codeItem, themeIndex) => {
        codeItem.representative_quotes.forEach((quoteContent, quoteIndex) => {
          newQuotes.push({
            id: quoteCounter++,
            content: quoteContent,
            themeId: themeIndex + 1,
            columnIndex: 0,
            order: quoteIndex,
          });
        });
      });

      quoteIdCounter.current = quoteCounter;
      setThemes(newThemes);
      setQuotes(newQuotes);
    }
  }, [codes, topicsQuestionsAnalysis, canvasScrollRef]);

  useEffect(() => {
    const themesPerRow = themesPerRowRef.current;
    const marginX = 20;
    const marginY = 20;
    const canvasScroll = canvasScrollRef.current;
    const baseLeft = canvasScroll ? canvasScroll.clientWidth / 2 + 1500 : 1500;
    let currentTop = canvasScroll ? canvasScroll.clientHeight / 2 + 1500 : 1500;

    const sortedThemes = [...themes].sort((a, b) => a.id - b.id);

    sortedThemes.forEach((theme, index) => {
      if (theme.isDragged) return;

      const rowIndex = Math.floor(index / themesPerRow);
      const colIndex = index % themesPerRow;

      if (colIndex === 0 && index > 0) {
        const previousRowStart = index - themesPerRow;
        const previousRowEnd = index;
        const previousRowThemes = sortedThemes.slice(previousRowStart, previousRowEnd);
        const maxHeightInPreviousRow = Math.max(
          ...previousRowThemes.map(t => themeHeightsRef.current[t.id] || 300)
        );
        currentTop += maxHeightInPreviousRow + marginY;
      }

      const left = baseLeft + colIndex * (400 + marginX);
      const top = currentTop;

      if (theme.left !== left || theme.top !== top) {
        updateThemePosition(theme.id, left, top);
      }
    });
  }, [themes, themeHeights, updateThemePosition, canvasScrollRef]);


  const updateQuotePosition = (id, left, top) => {
    setQuotes((prevQuotes) =>
      prevQuotes.map((quote) =>
        quote.id === id ? { ...quote, left, top } : quote
      )
    );
  };

  const updateQuoteTheme = (quoteId, themeId, columnIndex) => {
    setQuotes((prevQuotes) =>
      prevQuotes.map((quote) =>
        quote.id === quoteId
          ? { ...quote, themeId, columnIndex: columnIndex ?? quote.columnIndex }
          : quote
      )
    );
  };

  useEffect(() => {
    if (canvasScrollRef.current && canvasContentRef.current) {
      const canvasScroll = canvasScrollRef.current;
      const canvasContent = canvasContentRef.current;
      const contentWidth = canvasContent.offsetWidth * zoomLevel;
      const contentHeight = canvasContent.offsetHeight * zoomLevel;
      const scrollLeft = (contentWidth - canvasScroll.clientWidth) / 2;
      const scrollTop = (contentHeight - canvasScroll.clientHeight) / 2;
      canvasScroll.scrollLeft = scrollLeft;
      canvasScroll.scrollTop = scrollTop;
    }
  }, [zoomLevel]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className={`affinity-canvas ${isFullScreen ? "full-screen" : ""}`}
        ref={canvasRef}
        style={{ width: isFullScreen ? "100vw" : initialWidth, height: isFullScreen ? "100vh" : initialHeight }}
      >
        <div className="top-bar">
          <div className="download-icon" onClick={handleDownload}><FaDownload /></div>
        </div>
        <div className="canvas-scroll-container" ref={canvasScrollRef}>
          <div
            className="canvas-content"
            ref={(node) => {
              canvasContentRef.current = node;
              drop(node);
            }}
            style={{
              transform: `scale(${zoomLevel})`,
              backgroundColor: isOverCanvas && canDropCanvas ? "#e0e0e0" : "transparent",
            }}
          >
            <div className="themes-container">
              {themes.map((theme) => (
                <AffinityTheme
                  key={theme.id}
                  theme={theme}
                  quotes={quotes.filter((quote) => quote.themeId === theme.id)}
                  updateThemeTitle={updateThemeTitle}
                  removeTheme={removeTheme}
                  updateThemePosition={updateThemePosition}
                  moveTheme={moveTheme}
                  zoomLevel={zoomLevel}
                  canvasContentRef={canvasContentRef}
                  setQuotes={setQuotes}
                  updateQuotePosition={updateQuotePosition}
                  updateQuoteTheme={updateQuoteTheme}
                  activeTool={activeTool}
                  canvasScrollRef={canvasScrollRef}
                  setThemeHeight={handleSetThemeHeight}
                />
              ))}
            </div>
            {quotes
              .filter((quote) => quote.themeId === null)
              .map((quote, index) => {
                const leftPos = quote.left !== undefined ? quote.left : 1200 + index * 100;
                const topPos = quote.top !== undefined ? quote.top : 2000 + index * 100;
                return (
                  <AffinityQuote
                    key={quote.id}
                    quote={{ ...quote, left: leftPos, top: topPos }}
                    updateQuotePosition={updateQuotePosition}
                    updateQuoteTheme={updateQuoteTheme}
                    zoomLevel={zoomLevel}
                    canvasContentRef={canvasContentRef}
                    activeTool={activeTool}
                    canvasScrollRef={canvasScrollRef}
                  />
                );
              })}
          </div>
        </div>
        <div className="bottom-bar-actions">
          <button className="zoom-button" onClick={handleZoomIn}><FaSearchPlus /></button>
          <button className="zoom-button" onClick={handleZoomOut}><FaSearchMinus /></button>
          <button className="full-screen-toggle" onClick={toggleFullScreen}>
            {isFullScreen ? "Exit Full Screen" : "Full Screen"}
          </button>
        </div>
      </div>
    </DndProvider>
  );
};

export default AffinityCanvas;