import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { MdOutlineHome, MdOutlineBarChart,MdOutlineMenuBook, MdSearch, MdChevronLeft, MdChevronRight, 
  MdAccountCircle, MdContentCopy, MdMoreVert, MdArrowDropUp, MdArrowDropDown, 
  MdNewLabel, MdLabel, MdArrowRight, MdArrowLeft,MdExitToApp , MdArrowUpward, MdHelp, MdLogout} from 'react-icons/md';
import logo from '../assets/ripple_large_logo.svg';
import mixpanel from 'mixpanel-browser';
import ReportsPage from './ReportsPage';
import HomePage from './HomePage';
import KnowledgeBasePage from './KnowledgeBasePage';
import logoSmall from '../assets/ripple_logo.svg';
import axios from 'axios';
import home from '../assets/home.svg'
import reports from '../assets/reports.svg'
import help from '../assets/help.svg'
import lightning from '../assets/lightning.svg'
import repository from '../assets/repository.svg'
// import { Home, BarChart, Book } from 'react-feather';
import Subscriptions from './Subscriptions'; 
import Intercom from '@intercom/messenger-js-sdk';
import ProductTour from './ProductTour';
import { ProductTourSteps } from './ProductTourSteps'; 
import plus from '../assets/plus.svg';
import TopUpModal from "./TopUpModal";
import "./TopUpModal.css"; 

mixpanel.init('1702428ef9acdb5aa84df81256d5fe13', { debug: false, track_pageview: false, persistence: 'localStorage' });


const AppContent = () => {
  const location = useLocation();
  const [showTour, setShowTour] = useState(false);
  const [selectedNavItem, setSelectedNavItem] = useState('Home');
  

  const handleNavItemClick = (item) => {
    setSelectedNavItem(item);
  };


  useEffect(() => {
    mixpanel.track('DashboardHomePageViewed');
  }, []);

  const [drawerOpen, setDrawerOpen] = useState(false); // For expanding and collapsing drawer

  const handleUpgrade = () => {
    setCurrentView('Subscriptions');
    navigate('/subscriptions', { replace: true });
  };
  
  
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const openDrawer = () => {
    setDrawerOpen(true);
    // setShowText(true);  // Display text when drawer is expanded
  };
  
  const closeDrawer = () => {
    setDrawerOpen(false);
    // setShowText(false);  // Hide text when drawer is collapsed
  };
  
  
  const toggleUserMenu = () => {
    setUserMenuOpen(!userMenuOpen);
  };
  
  const [accessToken, setAccessToken] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const menuRef = useRef(null);
  const [userMenuOpen, setUserMenuOpen] = useState(false); // For user dropdown
  const [showText, setShowText] = useState(true);
  const drawerRef = useRef(null);
  const [currentView, setCurrentView] = useState('Home'); 
  const [interviewMinutesUsed, setInterviewMinutesUsed] = useState(0);
  const [interviewMinutesLimit, setInterviewMinutesLimit] = useState(0); 
  const [interviewUsagePercent, setInterviewUsagePercent] = useState(0);
  const [transcriptionMinutesUsed, setTranscriptionMinutesUsed] = useState(0);
  const [transcriptionMinutesLimit, setTranscriptionMinutesLimit] = useState(0); 
  const [transcriptionUsagePercent, setTranscriptionUsagePercent] = useState(0);
  const [recruitBalance, setRecruitBalance] = useState(0);

  const [planType, setPlanType]= useState('Free');

  const [balance, setBalance] = useState(0);
  const [userId, setUserId] = useState(null);

  const [showTopUpModal, setShowTopUpModal] = useState(false);



  const { loginWithRedirect, logout, user, isAuthenticated, isLoading, getAccessTokenSilently, getIdTokenClaims } = useAuth0();


  
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get('session_id');

    if (sessionId) {
      setCurrentView('Subscriptions'); // Set view to Subscriptions
      navigate({ search: queryParams.toString() }, { replace: true });
    }
  }, [location.search, navigate]);

  useEffect(() => {
    const fetchUsageData = async () => {

      if (accessToken) {
        try {

          const accessToken = await getAccessTokenSilently();
          const idToken = await getIdTokenClaims();
          
          const response = await fetch('https://dashboard-api.echovane.com/api/usage', {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'X-ID-Token': idToken.__raw,
            },
          });
          const data = await response.json();

          console.log("data ", data);
          
  
          setInterviewMinutesUsed(data.interview_minutes_used);
          setInterviewMinutesLimit(data.interview_minutes_limit);
          setTranscriptionMinutesUsed(data.transcription_minutes_used);
          setTranscriptionMinutesLimit(data.transcription_minutes_limit);
          setPlanType(data.plan_name);
          setUserId(data.user_id);
          setRecruitBalance(data.recruit_balance);
  
          const interviewUsagePercentage = (data.interview_minutes_used / data.interview_minutes_limit) * 100;
          setInterviewUsagePercent(interviewUsagePercentage);
          const transcriptionUsagePercentage = (data.transcription_minutes_used / data.transcription_minutes_limit) * 100;
          setTranscriptionUsagePercent(transcriptionUsagePercentage);
        } catch (error) {
          console.error('Error fetching usage data:', error);
          setError('Failed to fetch usage data. Please try again.');
        }
      }
    };
  
    fetchUsageData();
  }, [accessToken]);

  
  useEffect(() => {
    const handleClickOutside = (event) => {
      
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) && 
        !event.target.closest('.menu-button') 
      ) {
        setUserMenuOpen(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  

  useEffect(() => {
    const checkAndRedirect = async () => {
      if (!isLoading && !isAuthenticated) {
        try {
          const queryParams = new URLSearchParams(location.search);
          const screenHint = queryParams.get('screen_hint');
          await loginWithRedirect({
            appState: { returnTo: window.location.pathname },
            authorizationParams: {
              ...(screenHint && { screen_hint: screenHint }),
            },
          });
        } catch (error) {
          console.error('Login error:', error);
          setError('Failed to log in. Please try again.');
        }
      }
    };

    checkAndRedirect();
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  useEffect(() => {
    const fetchTokens = async () => {
      if (isAuthenticated && user) {
        try {
          const accessToken = await getAccessTokenSilently();
          const idTokenClaims = await getIdTokenClaims();
        
          setIdToken(idTokenClaims.__raw);
          setAccessToken(accessToken);
        } catch (error) {
          console.error('Error fetching tokens:', error);
          setError('Failed to fetch authentication tokens. Please try logging in again.');
        }
      }
    };

    fetchTokens();
  }, [isAuthenticated, user]);




  useEffect(() => {
    const drawer = drawerRef.current;

    if (drawer) {
    if (drawerOpen) {
  
      const handleTransitionEnd = () => {
        setShowText(true);
      };
      
      drawer.addEventListener('transitionend', handleTransitionEnd);
  
      return () => {
       
        drawer.removeEventListener('transitionend', handleTransitionEnd);
      };
    } else {
      
      setShowText(false);
    }

  }
  }, [drawerOpen]);


  if (!isAuthenticated) {
    return null;
  }

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }


  Intercom({
    app_id: 'irrdx7o2',
    user_id: userId? userId:-1, 
    name: user?.nickname,
    email: user?.email, 
    created_at: user?.updated_at, 
  });

  return (
    <>      

    {showTour && (
    <ProductTour steps={ProductTourSteps} onClose={() => setShowTour(false)} />)
    }
     { 

        <div className="app-container">

          <div className='drawer-container'>
          
          <div ref={drawerRef} 
            className={`drawer ${drawerOpen ? 'expanded' : 'collapsed'}`}
            onMouseEnter={openDrawer}
            onMouseLeave={closeDrawer}
          >
            <div className="logo-section">
            <img
              src={drawerOpen ? logo : logoSmall}
              alt="Echovane Logo"
              className="logo"
              style={!drawerOpen ? { width: '42px', height: '42px' } : {height: '42px' }}
            />


            </div>

            <div className='bold-line'></div>

            <nav className="nav-menu">
              <button
                className={`nav-item ${selectedNavItem === 'Home' ? 'selected' : ''}`}
                onClick={() => {
                  setCurrentView('Home');
                  handleNavItemClick('Home');
                }}
              >
                <img src={home} className="nav-item-svg" />
                <span className="nav-text">Home</span>
              </button>
              <div className="thin-line"></div>
              <button
                className={`nav-item ${selectedNavItem === 'Reports' ? 'selected' : ''}`}
                onClick={() => {
                  setCurrentView('Reports');
                  handleNavItemClick('Reports');
                }}
              >
                <img src={reports} className="nav-item-svg" />
                 <span className="nav-text">Reports</span>
              </button>
              <div className="thin-line"></div>
              <button
                className={`nav-item ${selectedNavItem === 'KnowledgeBase' ? 'selected' : ''}`}
                onClick={() => {
                  setCurrentView('KnowledgeBase');
                  handleNavItemClick('KnowledgeBase');
                }}
              >
                <img src={repository} className="nav-item-svg" />
                 <span className="nav-text">Knowledge Base</span>
              </button>
              <div className="thin-line"></div>
            </nav>


            {drawerOpen && (
              <div className="usage-meter-box">
                <div className='meter-label'>Usage (mins)</div>
                <div className="usage-item">
                <div className='usage-item-values-row'>
                  <div className="label">Interview</div>
                  <div className="meter-value">
                      {interviewMinutesUsed} 
                    </div>
                  </div>
                  <div className="meter">
                    <div className="meter-bar minutes" style={{ width: `${interviewUsagePercent}%` }}></div>
                  </div>

                </div>

                <div className="usage-item">
                <div className='usage-item-values-row'>
                  <div className="label">Transcription</div>
                  <div className="meter-value">
                      {transcriptionMinutesUsed} 
                    </div>
                    </div>

                  <div className="meter">
                    <div className="meter-bar minutes" style={{ width: `${transcriptionUsagePercent}%` }}></div>
                  </div>
                </div>

                {/* <div className='upgrade-button' onClick={handleUpgrade}> 
                <img src={lightning} className='upgrade-icon'/>
                <div className='upgrade-button-text'>Upgrade</div>
              </div> */}
              </div>

          )}

            {drawerOpen && (
            <div className="recruit-credits-box">
                 
              <div className="usage-item">
                <span className="meter-label">Recruit Balance <div className="balance-value">${recruitBalance?recruitBalance:0} </div> </span>
              </div>
              

              <div className='upgrade-button' onClick={() => setShowTopUpModal(true)}> 
                <img src={plus} className='upgrade-icon'/>
                <div className='upgrade-button-text'>Top Up</div>
              </div>
            </div>
          )}

            <div ref={menuRef} className="user-section" onClick={toggleUserMenu}>
              <button className="user-initial-circle" >
                <span>{user?.email.charAt(0).toUpperCase()}</span>
                </button>

                {drawerOpen && (
                <div className="user-email">{user?.email}</div> 
              )}

              {
                userMenuOpen?  <MdArrowDropDown/>:<MdArrowDropUp/>
              }

              {userMenuOpen && (
                <div className="user-menu-dropdown">
                  {/* <button className='user-menu-button' onClick={() => navigate('/account')}>
                  <MdAccountCircle /> Account
                  </button>

                  <button className='user-menu-button'   onClick={() => navigate('/plans')}>
                  <MdNewLabel />     Plans
                  </button>

                  <button className='user-menu-button' onClick={() => setCurrentView('Subscriptions')}>
                    <MdArrowUpward /> Upgrade
                  </button> */}

                  <button className='user-menu-button'  onClick={handleLogout}> 
                  <MdLogout  className='dropdown-icon'/>  Logout
                  </button>
                </div>
              )}

            </div>

          </div>
           </div>

          <main className="main-content">
            <div className='app-header-row'>
              {/* <div className='upgrade-button' onClick={handleUpgrade}> 
                <img src={lightning} className='upgrade-icon'/>
                <div className='upgrade-button-text'>Upgrade</div>
              </div>
                <img src={help} className='start-tour-btn' onClick={() => setShowTour(true)} ></img> */}
            </div>
          {currentView === 'Home' && (
            <HomePage
            interviewMinutesUsed={interviewMinutesUsed}
            interviewMinutesLimit={interviewMinutesLimit}
            planType={planType} 
          />
          )}

            {currentView === 'Reports' && (
              <ReportsPage/>
              )}

              {currentView === 'KnowledgeBase' && (
                <KnowledgeBasePage/>
              )}
              {currentView === 'Subscriptions' && <Subscriptions />}

          </main>
          </div>
        
        
      }




        {error && (
          <div className="error-message">
            {error}
          </div>
        )}

        <TopUpModal
          show={showTopUpModal}
          onClose={() => setShowTopUpModal(false)}
          success_path="top-up-success"  // Relative path
          cancel_path=""                // Return to home/dashboard on cancel
          getAccessTokenSilently={getAccessTokenSilently}
          getIdTokenClaims={getIdTokenClaims}
        />


    </>
  );
  
}

export default AppContent;