import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AppContent from './components/AppContent';
import CampaignBuilder from './components/CampaignBuilder';
import AnalyzeBuilder from './components/AnalyzeBuilder';
import CampaignReport from './components/CampaignReport';
import Participants from './components/Participants'; 
import ReportsPage from './components/Participants'; 
import TopUpSuccess from "./components/TopUpSuccess";
import KnowledgeBasePage from './components/Participants'; 
import Subscriptions from './components/Subscriptions'; 
import './App.css';
import PostLiveStep from './components/CampaignBuilderSteps/PostLiveStep';
import { useLocation } from 'react-router-dom';
// import Intercom from './components/Chat';

const App = () => {
  const location = useLocation(); // Get the current location

  return (
    <Routes>
      <Route
        path="/report/:campaignId"
        element={<CampaignReport />}
      />
      <Route
        path="/research/:id"
        element={<CampaignBuilder key={location.pathname + location.search} />}
      />
      <Route
        path="/research/:id/live"
        element={<CampaignBuilder key={location.pathname} showPostLiveStep />}
      />
      <Route path="/analyze/:campaignId?" element={<AnalyzeBuilder />} />
      <Route
        path="/research/:id/top-up-success"
        element={<TopUpSuccess />}
      />
      <Route path="/top-up-success" element={<TopUpSuccess />} />
      <Route path="/participants" element={<Participants />} />
      <Route path="/*" element={<AppContent />} />
      <Route path="/subscriptions" element={<Subscriptions />} />
    </Routes>
  );
};

export default App;