// src/auth/Auth0ProviderWithHistory.js

import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const Auth0ProviderWithHistory = ({ children }) => {
  const navigate = useNavigate();

// # REACT_APP_AUTH0_DOMAIN=dev-kziz0ei3mgwy5sya.us.auth0.com
// # REACT_APP_AUTH0_CLIENT_ID=64dU3uX3AdlR3Wq3By6kE6DQhiiX1ond
// # REACT_APP_AUTH0_AUDIENCE=https://dev-kziz0ei3mgwy5sya.us.auth0.com/api/v2/

  // const domain = "echovane.us.auth0.com";
  // const clientId = "VHTKD0AOiJNlAa9hMTdj4Wl7rjtwDvKm";
  // const audience = "https://echovane.us.auth0.com/api/v2/";

  // const domain = "dev-kziz0ei3mgwy5sya.us.auth0.com";
  // const clientId = "64dU3uX3AdlR3Wq3By6kE6DQhiiX1ond";
  // const audience = "https://dev-kziz0ei3mgwy5sya.us.auth0.com/api/v2/";

  const domain = "auth.echovane.com";
  const clientId = "VHTKD0AOiJNlAa9hMTdj4Wl7rjtwDvKm";
  const audience = "https://echovane.us.auth0.com/api/v2/";

  // const domain = "login.echovane.com";
  // const clientId = "64dU3uX3AdlR3Wq3By6kE6DQhiiX1ond";
  // const audience = "https://dev-kziz0ei3mgwy5sya.us.auth0.com/api/v2/";

  const onRedirectCallback = (appState, user) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: audience,
        scope: "openid profile email read:current_user"
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;