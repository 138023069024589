export const LANGUAGE_OPTIONS = [
  { value: "ar", label: "Arabic" },
  { value: "bg", label: "Bulgarian" },
  { value: "zh", label: "Chinese (Mandarin, Simplified)" },
  { value: "zh-CN", label: "Chinese (Mandarin, Simplified, Mainland China)" },
  { value: "zh-Hans", label: "Chinese (Simplified, Hans)" },
  { value: "zh-TW", label: "Chinese (Mandarin, Traditional)" },
  { value: "zh-Hant", label: "Chinese (Traditional, Hant)" },
  { value: "zh-HK", label: "Chinese (Cantonese, Traditional)" },
  { value: "hr", label: "Croatian" },
  { value: "cs", label: "Czech" },
  { value: "da", label: "Danish" },
  { value: "nl", label: "Dutch" },
  { value: "nl-BE", label: "Flemish" },
  { value: "en", label: "English" },
  { value: "en-US", label: "English (United States)" },
  { value: "en-AU", label: "English (Australia)" },
  { value: "en-GB", label: "English (Great Britain)" },
  { value: "en-NZ", label: "English (New Zealand)" },
  { value: "en-IN", label: "English (India)" },
  { value: "fr", label: "French" },
  { value: "fr-CA", label: "French (Canada)" },
  { value: "de", label: "German" },
  { value: "hi", label: "Hindi" },
  { value: "id", label: "Indonesian" },
  { value: "it", label: "Italian" },
  { value: "ja", label: "Japanese" },
  { value: "ko", label: "Korean" },
  { value: "ru", label: "Russian" },
  { value: "es", label: "Spanish" },
  { value: "es-419", label: "Spanish (Latin America)" },
  { value: "sv", label: "Swedish" },
];
  
  export const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      border: '1px solid #e0e0e0',
      boxShadow: state.isFocused ? '0 0 0 2px #7d47fb80' : 'none',
      cursor: 'pointer',
      fontSize: '12px',
      fontFamily: 'Nunito, sans-serif',
      color: '#4d4d4d',
      '&:hover': {
        borderColor: '#7d47fb',
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      fontSize: '12px',
      fontFamily: 'Nunito, sans-serif',
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '12px',
      fontFamily: 'Nunito, sans-serif',
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isSelected
        ? '#f0f0f0'
        : isFocused
        ? '#fafafa'
        : '#ffffff',
      color: '#191919',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '12px',
      fontFamily: 'Nunito, sans-serif',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '8px',
      marginTop: '4px',
    }),
  };
  