import React, { useState, useEffect } from 'react';
import { fetchPlanDataAPI } from '../ApiUtils';
import TopUpModal from "../TopUpModal";
import "../TopUpModal.css"; 
import plus from '../../assets/plus.svg';
import { useNavigate } from 'react-router-dom';

const WhoStep = ({
  audienceOption,
  setAudienceOption,
  numberOfParticipants,
  incentiveAmount,
  setShowRecruitmentSheet,
  setShowScreenerSheet,
  handleEditRecruitmentCriteria,
  handleEditScreenerQuestions,
  validationErrors,
  savedRecruitmentCriteria,
  savedScreenerQuestions,
  hasNonEmptyCriteria,
  externalProjectTitle,
  externalProjectDescription,
  handleParticipantsChange,
  handleIncentiveChange,
  handleExternalTitleChange,
  handleExternalDescriptionChange,
  getAccessTokenSilently,
  getIdTokenClaims,
  setValidationErrors,
  onBeforeTopUpModalOpen ,
  campaignId,
  campaignStatus
}) => {

  const [showPauseNotification, setShowPauseNotification] = useState(true);
  const [recruitBalance, setRecruitBalance] = useState(null);
  const [balanceError, setBalanceError] = useState('');
  const [showTopUpModal, setShowTopUpModal] = useState(false);
  const [participantCount, setParticipantCount] = useState(null);
  const navigate = useNavigate();

  const B2C_RECRUITMENT_FEE = 10;

  const basePath = `research/${campaignId}`;
  const success_path = `${basePath}/top-up-success`;
  const cancel_path = `${basePath}/top-up-cancel`;

  const handleTopUpClick = async () => {
    try {
      // 2) Call the parent's callback to save the step
      await onBeforeTopUpModalOpen();
      // 3) Then open the modal
      setShowTopUpModal(true);
    } catch (error) {
      console.error("Error saving who step before opening modal:", error);
    }
  };


  const handleViewParticipants = () => {
    navigate('/participants', { state: { campaignId } });
  };


  useEffect(() => {
    const fetchBalanceAndParticipants = async () => {
      try {
        const [balanceData, participantResponse] = await Promise.all([
          fetchPlanDataAPI({ getAccessTokenSilently, getIdTokenClaims }),
          campaignStatus === 'Live' ? fetchParticipantCount() : Promise.resolve({ participant_count: 0 })
        ]);
        
        setRecruitBalance(balanceData.recruit_balance || 0);
        setParticipantCount(participantResponse.participant_count);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    const fetchParticipantCount = async () => {
      const accessToken = await getAccessTokenSilently();
      const idTokenClaims = await getIdTokenClaims();
      const response = await fetch(
        `https://dashboard-api.echovane.com/api/participants/${campaignId}/participant_count`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'X-ID-Token': idTokenClaims.__raw,
          },
        }
      );
      if (!response.ok) throw new Error('Failed to fetch participant count');
      return response.json();
    };

    if (audienceOption === 'recruit') {
      fetchBalanceAndParticipants();
    }
  }, [audienceOption, campaignId, campaignStatus, getAccessTokenSilently, getIdTokenClaims]);

  useEffect(() => {
    if (audienceOption === 'recruit' && numberOfParticipants && incentiveAmount) {
      const requiredBalance = numberOfParticipants * (Number(incentiveAmount) + B2C_RECRUITMENT_FEE);
      if (requiredBalance > (recruitBalance || 0)) {
        setValidationErrors(prev => ({
          ...prev,
          balanceParticipants: `$${requiredBalance} recruit balance needed. Please top up.`,
          balanceIncentive: `$${requiredBalance} recruit balance needed. Please top up.`
        }));
      } else {
        setValidationErrors(prev => ({
          ...prev,
          balanceParticipants: '',
          balanceIncentive: ''
        }));
      }
    } else {
      setValidationErrors(prev => ({
        ...prev,
        balanceParticipants: '',
        balanceIncentive: ''
      }));
    }
  }, [numberOfParticipants, incentiveAmount, recruitBalance, audienceOption, setValidationErrors]);

  const renderBalanceNotification = () => {
    if (recruitBalance === null || participantCount === null) return null;
    if (participantCount > 0) return null; // Don't show message if recruitment has started
    
    return (
      <div className="balance-notification sufficient-balance">
        <p>Please note: Recruitment will commence within 24 hours of submitting your request here. Thank you!</p>
      </div>
    );
  };

  return (
    <div className="who-step">
      <div className='step-label'>Select target audience</div>
      <div className='step-description'>
    Choose Target your own audience below if you already have a list of users with whom you want to do the research. Choose Recruit audience if you want to recruit users from Echovane platform.
    </div>
      <div className="audience-options">
      <div className='who-option-block' onClick={() => setAudienceOption('own')}
        
        >
        <label className="radio-container">
          <input
            type="radio"
            value="own"
            checked={audienceOption === 'own'}
            onChange={() => setAudienceOption('own')}
          />
          <span className="custom-radio-circle"></span>
          
        </label>
        <div className='who-option-block-label'>
        Target your own audience
        </div>
        </div>
        <div className='who-option-block' onClick={() => setAudienceOption('recruit')}>
        <label className="radio-container">
          <input
            type="radio"
            value="recruit"
            checked={audienceOption === 'recruit'}
            onChange={() => setAudienceOption('recruit')}
          />
          <span className="custom-radio-circle"></span>
          
        </label>
        <div className='who-option-block-label'>
        Recruit audience
        </div>
        </div>
      </div>

      <hr className="full-width-line" />

      {audienceOption === 'recruit' && (
        <>
        {renderBalanceNotification()}

        <div className="info-cards-container">
            <div className="info-card">
              <div className="info-card-content">
                <span className="info-card-value">
                  {recruitBalance !== null ? `$${recruitBalance}` : <div className="modern-loader"></div>}
                </span>
                <span className="info-card-label">Recruit Balance</span>
              </div>
              <button className="info-card-action" onClick={handleTopUpClick}>
                {/* <img src={plus} className="action-icon" alt="Top up" /> */}
                Top Up
              </button>
            </div>

            {campaignStatus === 'Live' && (
              <div className="info-card">
                <div className="info-card-content">
                  <span className="info-card-value">
                    {participantCount !== null ? participantCount : <div className="modern-loader"></div>}
                  </span>
                  <span className="info-card-label">Number of Recruits applied</span>
                </div>
                <button className="info-card-action" onClick={handleViewParticipants}>
                  View Recruits
                </button>
              </div>
            )}
          </div>

          <div className="recruit-container-whole">

            <div className="recruit-container">
              <div className='recruit-input-sections'>

                <div className='input-section'>
                  <div className='internal-header'>Number of Participants</div>

                  <input
                    type="number"
                    className="input-box"
                    placeholder="Enter number of participants"
                    value={numberOfParticipants}
                    onChange={handleParticipantsChange}
                  />

                  {validationErrors.participants && (
                    <div className="err-message">{validationErrors.participants}</div>
                  )}

                </div>

                <div className="input-section">
                  <div className='internal-header'>Incentive Amount ($)</div>
                  <input
                    type="number"
                    className="input-box"
                    placeholder="Enter incentive amount"
                    value={incentiveAmount}
                    onChange={handleIncentiveChange}
                  />
                  {validationErrors.incentive && (
                    <div className="err-message">{validationErrors.incentive}</div>
                  )}
                                    {recruitBalance !== null && validationErrors.balanceParticipants && (
                    <div className="err-message">{validationErrors.balanceParticipants}</div>
                  )}
                </div>
              </div>

              <div className="recruit-left-section">
                <div className='internal-header'>Recruitment Criteria</div>

                {validationErrors.country && (
                  <div className="err-message">{validationErrors.country}</div>
                )}
                <p>
                  {savedRecruitmentCriteria && hasNonEmptyCriteria(savedRecruitmentCriteria)
                    ? ''
                    : 'Set the criteria for your audience, such as age, country, income, etc.'}
                </p>

                {savedRecruitmentCriteria && hasNonEmptyCriteria(savedRecruitmentCriteria) ? (
                  <div className="saved-criteria">
                    {savedRecruitmentCriteria.countries && savedRecruitmentCriteria.countries.length > 0 && (
                      <p><strong>Country:</strong> {savedRecruitmentCriteria.countries.join(', ')}</p>
                    )}
                    {savedRecruitmentCriteria.state && savedRecruitmentCriteria.state.length > 0 && (
                      <p><strong>State:</strong> {savedRecruitmentCriteria.state.join(', ')}</p>
                    )}
                    {savedRecruitmentCriteria.age && (savedRecruitmentCriteria.age.min || savedRecruitmentCriteria.age.max) && (
                      <p><strong>Age:</strong> {savedRecruitmentCriteria.age.min} - {savedRecruitmentCriteria.age.max}</p>
                    )}
                    {savedRecruitmentCriteria.education && savedRecruitmentCriteria.education.length > 0 && (
                      <p><strong>Education:</strong> {savedRecruitmentCriteria.education.join(', ')}</p>
                    )}
                    {savedRecruitmentCriteria.occupation && savedRecruitmentCriteria.occupation.length > 0 && (
                      <p><strong>Occupation:</strong> {savedRecruitmentCriteria.occupation.join(', ')}</p>
                    )}
                    {savedRecruitmentCriteria.incomeRange && savedRecruitmentCriteria.incomeRange.length > 0 && (
                      <p><strong>Income Range:</strong> {savedRecruitmentCriteria.incomeRange.join(', ')}</p>
                    )}
                    {savedRecruitmentCriteria.gender && (
                      <p><strong>Gender:</strong> {savedRecruitmentCriteria.gender}</p>
                    )}
                    {savedRecruitmentCriteria.language && savedRecruitmentCriteria.language.length > 0 && (
                      <p><strong>Language:</strong> {savedRecruitmentCriteria.language.join(', ')}</p>
                    )}
                    <button className="edit-button" onClick={handleEditRecruitmentCriteria}>Edit</button>
                  </div>
                ) : (
                  <button className="add-button" onClick={() => setShowRecruitmentSheet(true)}>Add</button>
                )}

              </div>

              <div className="recruit-right-section">
                <div className='internal-header'>Screener Questions</div>

                {savedScreenerQuestions.length > 0 ? (
                  <div className="saved-screener-questions">
                    <ul className='screener-question-list'>
                      {savedScreenerQuestions.map((q, index) => (
                        <li key={index}>{q.questionText}</li>
                      ))}
                    </ul>
                    <button className="edit-button" onClick={handleEditScreenerQuestions}>Edit</button>
                  </div>
                ) : (
                  <>
                    <p>Ask questions to further filter your audience.</p>
                    <button className="add-button" onClick={() => setShowScreenerSheet(true)}>Add</button>
                  </>
                )}

                {validationErrors.screenerQuestions && (
                  <div className="err-message">{validationErrors.screenerQuestions}</div>
                )}
              </div>

            </div>
            <div className="recruit-vertical-line"></div>
            <div className='recruit-communication'>
              <div className='input-section'>
                <div className='internal-header'>External Project Title</div>

                <input
                  type="text"
                  className="input-box-title"
                  placeholder="Enter project title which recruits will see"
                  value={externalProjectTitle}
                  onChange={handleExternalTitleChange}
                />

                {validationErrors.externalProjectTitle && (
                  <div className="err-message">{validationErrors.externalProjectTitle}</div>
                )}
              </div>

              <div className="input-section">
                <div className='internal-header'>External Project Description</div>
                <textarea
                  className="input-box-description"
                  placeholder="Enter project description which recruits will see."
                  value={externalProjectDescription}
                  onChange={handleExternalDescriptionChange}
                />
                {validationErrors.externalProjectDescription && (
                  <div className="err-message">{validationErrors.externalProjectDescription}</div>
                )}
              </div>

            </div>
          </div>
        </>
      )}

  {showTopUpModal && (
              <TopUpModal 
                show={showTopUpModal}
                onClose={() => setShowTopUpModal(false)}
                success_path={success_path}  
                cancel_path={cancel_path}    
                getAccessTokenSilently={getAccessTokenSilently}
                getIdTokenClaims={getIdTokenClaims}
              />
            )}
    </div>
  );
};

export default WhoStep;
