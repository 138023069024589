import './AnalyzeBuilder.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import upload from '../assets/upload.svg';
import { LANGUAGE_OPTIONS, customSelectStyles } from './languageConfig';

import { useAuth0 } from '@auth0/auth0-react';
import Select from 'react-select';
import ObjectiveStep from './CampaignBuilderSteps/ObjectiveStep';
import { FaSave, FaArrowLeft, FaTrash, FaDownload } from 'react-icons/fa'; 
import cross from '../assets/cross.svg';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { useEffect, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { createCampaign, createInterview , updateCampaign, fetchTranscripts, fetchAudioUrl,   requestPresignedUrlsAnalyze,
    completeMultipartUpload, fetchCampaignData,
    abortMultipartUpload,
    analyzeInterviews,
    deleteTranscript,} from './ApiUtils';
import ConfirmDialog from './ConfirmDialog';
import { useLocation, useParams } from 'react-router-dom';

import ProgressContainer from './ProgressContainer'; 


const AnalyzeBuilder = () => {
    const location = useLocation();
    const [campaign, setCampaign] = useState(null); 
    const { campaignId } = useParams(); 
    const navigate = useNavigate();
    const [projectName, setProjectName] = useState(campaign ? campaign.name : '');
    const [backendFiles, setBackendFiles] = useState([]); 
    const [droppedFiles, setDroppedFiles] = useState([]); 
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [deleteFileId, setDeleteFileId] = useState(null);
    const [interviewId, setInterviewId] = useState(null);
    const [deletingFiles, setDeletingFiles] = useState({});

    const allFiles = [...backendFiles, ...droppedFiles];

    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null);
    const maxSize = 1073741824; // 1GB in bytes
    const { loginWithRedirect, logout, user, isAuthenticated, isLoading, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const handleBackClick = () => {
        navigate(-1);
    };
    const [researchObjective, setResearchObjective] = useState('');
    const [step, setStep] = useState(0);

    const steps = [{ name: 'Upload', type: 'upload'}, { name: 'Objective', type: 'objective'} ];

    const [uploadProgress, setUploadProgress] = useState({});
    const [conversationLanguage, setConversationLanguage] = useState(campaign? campaign.language : 'en');
    

    const handleResearchObjectiveChange = (e) => setResearchObjective(e.target.value);

    const continueStep = () => {
        if (step < steps.length - 1) {
            setStep(step + 1);
        } else {
            // Handle the final step submission logic here, if required.
            
        }
    };
    

    const onDrop = async (acceptedFiles) => {
        const newFiles = acceptedFiles.map((file) => ({
            file,
            id: new Date().getTime() + file.name, // Unique identifier
            uploaded: false,
            abortController: new AbortController(),
        }));
    
        setDroppedFiles((prev) => [...newFiles, ...prev]);
    
        try {
            if (!interviewId) {
                const interview = await createInterview({
                    campaignId: campaign.id,
                    getAccessTokenSilently,
                    getIdTokenClaims,
                    interviewType: 'live',
                });

                // console.log("interview data " , interview);
    
                if (interview && interview.id) {
                   
                    setInterviewId(interview.id);
                   
                    await requestAndUploadFiles(newFiles, interview.id);
                } else {
                    throw new Error('Failed to create interview.');
                }
            } else {
                await requestAndUploadFiles(newFiles, interviewId);

            }
        } catch (error) {
            console.error('Error handling file drop:', error);
            alert('Error processing files.');
        }
    };
    
    const requestAndUploadFiles = async (filesToUpload, interviewId) => {
        try {

            const fileNames = filesToUpload.map((fileWrapper) => fileWrapper.file.name);
            const fileSizes = filesToUpload.map((fileWrapper) => fileWrapper.file.size);
    
            const presignedUrls = await requestPresignedUrlsAnalyze({
                interviewId,
                fileNames,
                fileSizes,
                getAccessTokenSilently,
                getIdTokenClaims,
              });
            // console.log("presigned urls ", presignedUrls);
    
            const uploadPromises = presignedUrls.map(async (fileUploadData) => {
                const fileWrapper = filesToUpload.find((f) => f.file.name === fileUploadData.file_name);
                const file = fileWrapper.file;
                fileWrapper.transcriptId = fileUploadData.transcript_id;
    
                try {
                    if (fileUploadData.multipart) {
                        // Handle multipart upload
                        await uploadMultipartFile(file, fileUploadData, interviewId, fileWrapper.transcriptId, fileWrapper.abortController);
                    } else {
                        // Handle single upload
                        await uploadSingleFile(file, fileUploadData.url, fileUploadData.key, fileWrapper.transcriptId, fileWrapper.abortController);
                    }
    
                    // Mark the file as uploaded after successful upload
                    fileWrapper.uploaded = true;
                    setDroppedFiles((prevFiles) =>
                        prevFiles.map((f) =>
                            f.file.name === fileUploadData.file_name ? { ...f, uploaded: true } : f
                        )
                    );
                } catch (error) {
                    console.error(`Error uploading file ${file.name}:`, error);
                    alert(`Failed to upload file ${file.name}.`);
                }
            });
            
            await Promise.all(uploadPromises);
            
        } catch (error) {
            console.error('Error requesting presigned URLs or uploading files:', error);
            alert('Failed to upload files.');
        }
    };

    const uploadSingleFile = async (file, url, key, transcriptId, abortController) => {
        try {
            await axios.put(url, file, {
                headers: { 'Content-Type': 'application/octet-stream' },
                signal: abortController.signal,
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / file.size
                    );
                    setUploadProgress((prev) => ({
                        ...prev,
                        [file.name]: percentCompleted,
                    }));
                },
            });
        } catch (error) {
            if (axios.isCancel(error)) {
              
                await deleteBackendFile(transcriptId);
            } else {
                console.error(`Error uploading file ${file.name}:`, error);
                alert(`Failed to upload file ${file.name}.`);
            }
        }
    };

    const uploadMultipartFile = async (file, fileUploadData, interviewId, transcriptId, abortController) => {
        const { upload_id, parts, key } = fileUploadData;
        const chunkSize = 5 * 1024 * 1024; // 5MB
        const fileSize = file.size;
        const numParts = parts.length;
        const promises = [];
        const etags = [];
        const partProgress = {}; // To track progress of each part
    
        for (let index = 0; index < numParts; index++) {
            const start = index * chunkSize;
            const end = Math.min(start + chunkSize, fileSize);
            const blobPart = file.slice(start, end);
            const partNumber = index + 1;
            const presignedUrl = parts[index].url;
            partProgress[partNumber] = 0; // Initialize progress for this part
    
            const uploadPromise = axios.put(presignedUrl, blobPart, {
                onUploadProgress: (progressEvent) => {
                    // Update the bytes uploaded for this part
                    partProgress[partNumber] = progressEvent.loaded;
    
                    // Calculate total bytes uploaded across all parts
                    const totalLoaded = Object.values(partProgress).reduce((acc, loaded) => acc + loaded, 0);
    
                    // Compute overall progress percentage
                    const percentCompleted = Math.round((totalLoaded * 100) / fileSize);
    
                    // Update the upload progress state
                    setUploadProgress((prev) => ({
                        ...prev,
                        [file.name]: percentCompleted,
                    }));
                },
                signal: abortController.signal,
            }).then(response => {
                const etag = response.headers.etag;
                etags.push({ ETag: etag, PartNumber: partNumber });
            }).catch(error => {
                if (axios.isCancel(error)) {
                    // console.log(`Upload of part ${partNumber} cancelled for ${file.name}`);
                } else {
                    console.error(`Error uploading part ${partNumber} of ${file.name}:`, error);
                    alert(`Failed to upload file ${file.name}.`);
                }
                throw error;
               
            });
    
            promises.push(uploadPromise);
        }
    
        try {

            await Promise.all(promises);

            if (etags.length === numParts) {
    
            await completeMultipartUpload({
                interviewId,
                transcriptId,
                fileName: file.name,
                uploadId: upload_id,
                etags,
                getAccessTokenSilently,
                getIdTokenClaims,
              });
        }
        } catch (error) {
            if (axios.isCancel(error)) {
                await abortMultipartUpload({
                    interviewId,
                    transcriptId,
                    fileName: file.name,
                    uploadId: upload_id,
                    getAccessTokenSilently,
                    getIdTokenClaims,
                  });
                await deleteBackendFile(transcriptId);
            } else {
                console.error(`Error completing multipart upload for ${file.name}:`, error);
                alert(`Failed to upload file ${file.name}.`);
            }
        }
    };
    
    
    
    

    const handleSave = async () => {
        try {
            const campaignData = {
                business_objective: researchObjective,
                conversation_language: conversationLanguage,
                name: projectName,
            };
    
            // Pass the parameters correctly
            const updatedCampaign = await updateCampaign(
                {
                campaignData: campaignData,
                campaignId: campaign.id,
                getAccessTokenSilently,
                getIdTokenClaims
                }
            );
    
            setCampaign(updatedCampaign);
           
        } catch (error) {
            alert('Failed to save the campaign. Please try again.');
            console.error('Error during save:', error);
        }
    };
    
    
    
      const handleStepChange = (newStep, navigatedBack = false) => {
        setStep(newStep);
    };
    
    

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: { 'audio/*': [], 'video/*': [] },
    });

    
    const handleSubmit = async () => {
        try {
            let updated = false;
    
            if (researchObjective !== campaign.business_objective || projectName != campaign.name ||  conversationLanguage != campaign.conversation_language) {
                const campaignData = {
                    business_objective: researchObjective,
                    conversation_language: conversationLanguage,
                    name: projectName,
                };
                
                const updatedCampaign = await updateCampaign(
                    {
                        campaignData: campaignData,
                        campaignId: campaign.id,
                        getAccessTokenSilently,
                        getIdTokenClaims
                        }
                );

                setCampaign(updatedCampaign);
                updated = true;
            }

                if (interviewId) {
                    await analyzeInterviews({
                        campaignId: campaign.id,
                        interviewId,
                        getAccessTokenSilently,
                        getIdTokenClaims,
                      });
            }
    

        } catch (error) {
            console.error('Error during submission:', error);
        } finally {
            handleBackClick();
        }
    };
    
    
    

    const handleDelete = async (fileId) => {
        const isBackendFile = backendFiles.some((file) => file.id === fileId);
        const fileToDelete = allFiles.find((file) => file.id === fileId);
        const transcriptId = fileToDelete.transcriptId;
        setDeletingFiles((prev) => ({ ...prev, [fileId]: true }));

        try {
        if (isBackendFile) {
            // Check if written_transcript is not null
            if (fileToDelete.written_transcript) {
                // Show the dialog with options
                setDeleteFileId(fileId);
                setIsDeleteDialogOpen(true);
            } else {
                // Proceed to delete directly
                await deleteBackendFile(fileId);
            }
        } else {
            await deleteBackendFile(transcriptId);
            setDroppedFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
        }
    }catch (error) {
        console.error('Error deleting file:', error);
        alert('Failed to delete the file. Please try again.');
    } finally {
        // Remove the file from deletingFiles after deletion attempt
        setDeletingFiles((prev) => {
            const updated = { ...prev };
            delete updated[fileId];
            return updated;
        });
    }

    };

    const deleteBackendFile = async (fileId) => {
        try {
            await deleteTranscript({
                transcriptId: fileId,
                getAccessTokenSilently,
                getIdTokenClaims,
              });
    
            setBackendFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
        } catch (error) {
            console.error('Error deleting backend file:', error);
            alert('Failed to delete the file. Please try again.');
        }
    };
    
    const handleJustDelete = async () => {
        setIsDeleteDialogOpen(false);
        await deleteBackendFile(deleteFileId);
    };
    
    const handleDeleteAndRerun = async () => {
        setIsDeleteDialogOpen(false);
        await deleteBackendFile(deleteFileId);
    
        try {

            await analyzeInterviews({
                campaignId: campaign.id,
                interviewId,
                refreshAnalysis: true,
                getAccessTokenSilently,
                getIdTokenClaims,
              });
        } catch (error) {
            console.error('Error during re-analysis:', error);
            alert('Failed to rerun analysis. Please try again.');
        }
    };
    
    
    
    const handleDownload = async (transcriptId) => {
        try {
            
            const audioUrlData = await fetchAudioUrl({transcriptId, getAccessTokenSilently, getIdTokenClaims});
    
            if (audioUrlData && audioUrlData.presigned_url) {
                const link = document.createElement('a');
                link.href = audioUrlData.presigned_url;
                link.download = transcriptId|| 'downloaded_audio_file';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                alert('Failed to fetch the audio URL for download.');
            }
        } catch (error) {
            console.error('Error during file download:', error);
            alert('An error occurred while trying to download the file.');
        }
    };
    

    const handleAbort = (id) => {
        setDroppedFiles((prevFiles) => 
            prevFiles.filter((file) => {
                if (file.id === id && file.abortController) {
                    file.abortController.abort(); // Abort the upload
                    return false; // Exclude this file from the list
                }
                return true; // Keep other files
            })
        );
    };
    
    

    useEffect(() => {
        const loadCampaign = async () => {
            try {
                setLoading(true);
                const fetchedCampaign = await fetchCampaignData({
                    campaignId,
                    getAccessTokenSilently,
                    getIdTokenClaims,
                });
    
                if (fetchedCampaign) {
                    setCampaign(fetchedCampaign);
                    
                    if (fetchedCampaign.business_objective) {
                        setResearchObjective(fetchedCampaign.business_objective);
                        setConversationLanguage(fetchedCampaign.conversation_language);
                    }
                    if (fetchedCampaign.name) {
                        setProjectName(fetchedCampaign.name);
                    }
                    if (fetchedCampaign.live_link) {
                        setInterviewId(fetchedCampaign.live_link);
                    }
                }
            } catch (error) {
                console.error('Error loading campaign:', error);
                setError('Failed to load campaign');
            } 
        };
    
        if (campaignId) {
            loadCampaign();
        }
    }, []);
    
    const loadTranscripts = async () => {
        try {
            setLoading(true);
            if (campaign?.live_link) {
                const transcripts = await fetchTranscripts(
                    {
                    interviewId:interviewId,
                    getAccessTokenSilently,
                    getIdTokenClaims
                    }
                );

                if (transcripts?.transcripts?.length) {
                    const transcriptFiles = transcripts.transcripts.map((t) => ({
                        id: t.id,
                        file: { name: t.uploaded_file_name }, // Mimic a file object for display
                        uploaded: true,
                        written_transcript: t.written_transcript, 
                    }));
                    setBackendFiles(transcriptFiles);
                }
            }
        } catch (error) {
            console.error('Error loading transcripts:', error);
            setError('Failed to load transcripts');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadTranscripts();
    }, [campaign]);
    

      if (error) return <div>Error: {error}</div>;

    return (

        <div className='analyze-builder-container-parent'>
<div className="builder-header">
            <div className="back-button-campaign-builder" onClick={handleBackClick}>
             <img src={cross} className='cross-icon'/>
            </div>

            <input
              type="text"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              placeholder="Add project name"
              className="campaign-name-input" />

            <div className="button-container">
                    {step > 0 && (
                      <button className="button-container-back-button" onClick={() => handleStepChange((step - 1), true)}>
                        {/* <FaArrowLeft />  */}
                        Previous
                      </button>

                    )}
                    <button className="save-button" onClick={handleSave}>
                      {/* <FaSave /> */}
                       Save
                    </button>
                    {step === steps.length - 1 ? (
                    <button
                      className="continue-button"
                      onClick={handleSubmit}
                      disabled={loading }
                    >
                      {loading ? (
                        <ThreeDots color="#fff" height={10} width={24} />
                      ) : (
                        "Submit"
                      )}
                    </button> ):(
                        <button
                        className="continue-button"
                        onClick={continueStep}
                        disabled={loading }
                        >
                        {loading ? (
                            <ThreeDots color="#fff" height={10} width={24} />
                        ) : (
                            "Next"
                        )}
                        </button> 
                    )}

                  </div>

          </div>

          <div className="builder-container" onClick={(e) => e.stopPropagation()}>
          <ProgressContainer
                steps={steps}
                currentStep={step}
                onStepChange={handleStepChange}
              />
        
        <div className="builder-content">

           
             {step === 0 && (
                <><div className='analyze-upload-header-container'>
                        <div className='step-label'>Upload audio or video files to transcribe and analyse</div>

                    </div>
                    <div className="language-picker-container">
                                <div className="language-picker-label">Select Recording Language</div>
                                <Select
                                    className="language-select"
                                    styles={customSelectStyles}
                                    options={LANGUAGE_OPTIONS}
                                    value={
                                    LANGUAGE_OPTIONS.find((opt) => opt.value === conversationLanguage) || 
                                    LANGUAGE_OPTIONS.find((opt) => opt.value === "en") // Default to English
                                    }
                                    onChange={(selectedOption) => setConversationLanguage(selectedOption.value)}
                                    placeholder="-- Select Language --"
                                />
                                </div>
                        <div className="analyze-dropzone-container" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="analyze-dropzone-content">
                                <img src={upload} className='analyze-upload-image' />
                                <p className="analyze-dropzone-text">
                                    Drag and drop your meeting recordings or click to choose
                                </p>
                                <p className="analyze-dropzone-hint">Max. file size 1 GB</p>
                            </div>
                        </div>
                        

                            

                        <div className="analyze-uploads-container">
                        {loading ? (
                            <div className="loading-indicator"></div>
                                ) : (
                            [...droppedFiles, ...backendFiles].map((fileWrapper) => {
                                const isDroppedFile = droppedFiles.some(
                                    (droppedFile) => droppedFile.id === fileWrapper.id
                                );
                                return (
                                <div key={fileWrapper.id} className="analyze-upload-item">
                                    <span className="analyze-file-name">{fileWrapper.file.name}</span>

                                    {fileWrapper.uploaded ? (
                                        isDroppedFile ? (
                                            <span className="analyze-success-tick">&#10003;</span> // Green tick only for dropped files
                                        ) : null
                                    ) : (
                                        <div className="analyze-progress-bar">
                                            {fileWrapper.uploaded  && isDroppedFile ? (
                                                <span className="analyze-success-tick">&#10003;</span> // Green tick
                                            ) : (
                                                <div
                                                    className="progress-bar-fill"
                                                    style={{ width: `${uploadProgress[fileWrapper.file.name] || 0}%` }}
                                                ></div>
                                            )}
                                        </div>)}

                                    <div className="analyze-actions">
                                        {fileWrapper.uploaded ? (
                                            // Show Download and Delete buttons after upload is complete
                                            <> {!isDroppedFile && (
                                                <button
                                                    className="analyze-download-button"
                                                    onClick={() => handleDownload(fileWrapper.id)}
                                                >
                                                    <FaDownload />
                                                </button>)}
                                                {deletingFiles[fileWrapper.id] ? (
                                                        <div className="delete-loader">
                                                            <ThreeDots color="#7d47fb" height={15} width={30} />
                                                        </div>
                                                    ) : (
                                                        <button
                                                            className="analyze-delete-button"
                                                            onClick={() => handleDelete(fileWrapper.id)}
                                                        >
                                                            <FaTrash />
                                                        </button>
                                                    )}
                                            </>
                                        ) : (
                                            // Show Abort button while upload is in progress
                                            <button
                                                className="analyze-abort-button"
                                                onClick={() => handleAbort(fileWrapper.id)}
                                                disabled={!fileWrapper.abortController}
                                            >
                                                Cancel
                                            </button>
                                        )}
                                    </div>


                                </div>
                            )}
                        
                        ))}
                        </div></>
        )}

            {step === 1 && (
            <ObjectiveStep
                researchObjective={researchObjective}
                setResearchObjective={setResearchObjective}
                selectedBlock={5}
          />
          )}

            </div>
            </div>
        
        {isDeleteDialogOpen && (
            <ConfirmDialog
                isOpen={isDeleteDialogOpen}
                onClose={() => setIsDeleteDialogOpen(false)}
                title="Delete File"
                description="Do you want to refresh the analysis after deleting this file?"
                options={[
                    { label: 'Cancel', onClick: () => setIsDeleteDialogOpen(false), className: 'confirm-dialog-cancel-button' },
                    { label: 'Delete Only', onClick: handleJustDelete, className: 'confirm-button' },
                    { label: 'Delete and Refresh Analysis', onClick: handleDeleteAndRerun, className: 'confirm-button' },
                ]}
            />
        )}

            </div>
    );
};

export default AnalyzeBuilder;
