import React, { useRef, useEffect, useState } from 'react';
import './SeeWork.css';

const SeeWork = ({ transcripts, highlights, highlightsLoading, formatDate }) => {
  const containerRef = useRef(null);
  const transcriptRefs = useRef({});
  const [currentTranscriptId, setCurrentTranscriptId] = useState(null);

  // Now accepts an extra parameter: conversationId
  const insertHighlights = (lineText, lineStart, conversationId) => {
    const lineHighlights = highlights
      // Filter only highlights for this conversation
      .filter((hl) => hl.interview_conversation_id === conversationId)
      .filter((hl) => {
        const hlStart = hl.start_char;
        const hlEnd = hl.end_char;
        const lineEnd = lineStart + lineText.length;
        return hlEnd >= lineStart && hlStart <= lineEnd;
      })
      .map((hl) => ({
        ...hl,
        relStart: Math.max(0, hl.start_char - lineStart),
        relEnd: Math.min(lineText.length, hl.end_char - lineStart),
        summary: hl.summary?.summary || '',
      }))
      .filter((hl) => hl.relEnd > hl.relStart);

    if (lineHighlights.length === 0) {
      return lineText;
    }

    const boundaries = [];
    lineHighlights.forEach((hl) => {
      boundaries.push({ index: hl.relStart, type: 'start', summary: hl.summary });
      boundaries.push({ index: hl.relEnd, type: 'end', summary: hl.summary });
    });

    boundaries.sort((a, b) => {
      if (a.index !== b.index) return a.index - b.index;
      if (a.type === b.type) return 0;
      return a.type === 'start' ? -1 : 1;
    });

    const elements = [];
    let activeSummaries = new Set();
    let prevIndex = 0;

    for (let i = 0; i < boundaries.length; i++) {
      const boundary = boundaries[i];

      if (boundary.index > prevIndex) {
        const segmentText = lineText.slice(prevIndex, boundary.index);

        if (activeSummaries.size === 0) {
          elements.push(segmentText);
        } else {
          // highlighted text
          elements.push(
            <span
              key={`hl-${prevIndex}-end`}
              className="transcript-highlighted-text">
              {segmentText}
              {[...activeSummaries].map((summary, cIdx) => (
                <span
                  key={`summary-${prevIndex}-end-${cIdx}`}
                  className="highlight-summary-box">
                  {summary}
                </span>
              ))}
            </span>
          );
        }
        prevIndex = boundary.index;
      }

      if (boundary.type === 'start') {
        activeSummaries.add(boundary.summary);
      } else {
        activeSummaries.delete(boundary.summary);
      }
    }

    // Handle trailing text
    if (prevIndex < lineText.length) {
      const segmentText = lineText.slice(prevIndex);

      if (activeSummaries.size === 0) {
        elements.push(segmentText);
      } else  {
        elements.push(
          <span
            key={`hl-${prevIndex}-end`}
            className="transcript-highlighted-text">
            {segmentText}
            {[...activeSummaries].map((summary, cIdx) => (
              <span
                key={`summary-${prevIndex}-end-${cIdx}`}
                className="highlight-summary-box">
                {summary}
              </span>
            ))}
          </span>
        );
      }
      
    }

    return elements;
  };

  const getSpeakerClass = (speaker) => {
    // e.g. "Speaker 1" => "speaker-1", "User" => "user", etc.
    return speaker.toLowerCase().replace(/\s+/g, '-');
  };

  const formatTranscript = (transcript, conversationId) => {
    const lines = transcript.split('\n');
    let offset = 0; // to track overall character positions

    return lines
      .map((originalLine, index) => {
        let line = originalLine;
        let prefixLength = 0;
        
        if (!/^\d{4}-\d{2}-\d{2}/.test(originalLine)) {
          const prefixMatch = line.match(
            /^(?:\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}(?:\.\d{1,3})? - (?:User|Echo|Speaker\s*\d+):\s*)/i
          );
          if (prefixMatch) {
            prefixLength = prefixMatch[0].length;
            line = line.slice(prefixLength);
          }
        }
        
        const lineStart = offset;
        const displayedLineStart = lineStart;
        offset += originalLine.length + 1; // +1 for newline

        // Handle special lines
        if (line.includes('USER_LONG_SILENCE_DETECTED')) {
          return (
            <div key={index} className="transcript-special">
              <span style={{ fontSize: '0.8em', textAlign: 'center', width: '100%' }}>
                User didn't speak for a while
              </span>
            </div>
          );
        } else if (line.includes('ECHO_LONG_SILENCE_DETECTED')) {
          return (
            <div key={index} className="transcript-special">
              <span style={{ fontSize: '0.8em', textAlign: 'center', width: '100%' }}>
                Echo got interrupted
              </span>
            </div>
          );
        }

        // Timestamp-based format (Format 1)
        const timestampFormatMatch = line.match(
          /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2} - (User|Echo|Speaker \d+): (.*)$/
        );
        if (timestampFormatMatch) {
          const [, speaker, text] = timestampFormatMatch;
          const speakerClass = getSpeakerClass(speaker);
          // Calculate the end character index (inclusive)
          const endIndex = displayedLineStart + text.length - 1;

          let timestampFormatMatchLength = line.indexOf(text);

          return (
            <div key={index} className={`sw-transcript-line ${speakerClass}`}>
              <div className="speaker">
                {speaker}{' '}
              </div>
              <div className="text">
                {insertHighlights(text, displayedLineStart + timestampFormatMatchLength, conversationId)}
              </div>
            </div>
          );
        }

        // [Speaker X]: format (Format 2)
        const speakerFormatMatch = line.match(/^\[(Speaker \d+)\]: (.*)$/);
        
        if (speakerFormatMatch) {
          const [, speaker, text] = speakerFormatMatch;
          const speakerClass = getSpeakerClass(speaker);
          const endIndex = displayedLineStart + text.length - 1;

          let speakerFormatLength = line.indexOf(text);
          
          return (
            <div key={index} className={`sw-transcript-line ${speakerClass}`}>
              <div className="speaker">
                {speaker}{' '}
              </div>
              <div className="text">
                {insertHighlights(text, displayedLineStart + speakerFormatLength, conversationId)}
              </div>
            </div>
          );
        }

        const plainFormatMatch = line.match(/^(User|Echo): (.*)$/i);
          if (plainFormatMatch) {
            const [, speaker, text] = plainFormatMatch;
            const speakerClass = getSpeakerClass(speaker);
            const endIndex = displayedLineStart + text.length - 1;

            let plainFormatLength = line.indexOf(text);

            return (
              <div key={index} className={`sw-transcript-line ${speakerClass}`}>
                <div className="speaker">{speaker}</div>
                <div className="text">
                  {insertHighlights(text, displayedLineStart + plainFormatLength, conversationId)}
                </div>
              </div>
            );
          }

        // If unrecognized, skip rendering this line
        return null;
      })
      .filter(Boolean);
  };

  useEffect(() => {
    const observerOptions = {
      root: containerRef.current,
      // We want to track all intersection ratios from 0..1 (in increments of 0.01).
      threshold: Array.from({ length: 101 }, (_, i) => i / 100),
    };
  
    const observerCallback = (entries) => {
      let bestMatch = { ratio: 0, id: null };
  
      entries.forEach((entry) => {
        // Track which transcript is the most visible
        if (entry.intersectionRatio > bestMatch.ratio) {
          bestMatch = {
            ratio: entry.intersectionRatio,
            id: entry.target.getAttribute('data-transcript-id'),
          };
        }
      });
     
      if (bestMatch.ratio >= 0.1 && bestMatch.id !== currentTranscriptId) {
        setCurrentTranscriptId(bestMatch.id);
      }
    };
  
    const observer = new IntersectionObserver(observerCallback, observerOptions);
    Object.values(transcriptRefs.current).forEach((ref) => {
      if (ref) observer.observe(ref);
    });
  
    return () => {
      observer.disconnect();
    };
  }, [transcripts, currentTranscriptId]);
  

  // ---------------------------
  // Scroll to transcript box on click
  // ---------------------------
  const scrollToTranscript = (transcriptId) => {
    const ref = transcriptRefs.current[transcriptId];
    if (ref) {
      ref.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className="see-work-container">
      {highlightsLoading ? (
        <div className="loading-indicator-container">
          <div className="loading-indicator"></div>
        </div>
      ) : (
        <div className="see-work-flex">
          {/* Left side: transcript list */}
          <div className="see-work-transcripts-list">
            {/* <h3>All Transcripts</h3> */}
            {transcripts.map((t) => (
              <div
                key={t.id}
                className={`see-work-transcript-item ${
                  currentTranscriptId === String(t.id) ? 'selected' : ''
                }`}
                onClick={() => {
                  setCurrentTranscriptId(t.id);
                  console.log("current transcript id ", currentTranscriptId);
                  scrollToTranscript(t.id)
                }}
              >
                <div>{formatDate(t.created_at)}</div>
              </div>
            ))}
          </div>

          {/* Middle: transcript viewer */}
          <div className="see-work-transcript-content" ref={containerRef}>
            {transcripts.map((t) => (
              <div
                key={t.id}
                className="transcript-box"
                data-transcript-id={t.id}
                ref={(el) => (transcriptRefs.current[t.id] = el)}
              >
                <div className="transcript-box-header">
                  <span>{formatDate(t.created_at)}</span>
                </div>
                <div className="transcript-box-content">
                  {t.written_transcript ? (
                    formatTranscript(t.written_transcript, t.id)
                  ) : (
                    <p>No transcript available.</p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SeeWork;
