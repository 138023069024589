// Participants.js

import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import './Participants.css';
import { format } from 'date-fns';
import cross from '../assets/cross.svg';
import { FaLinkedin } from 'react-icons/fa';
import respondentIoLogo from '../assets/respondent_io_logo.png';
import userInterviewsLogo from '../assets/user_interviews_logo.png';
import { MdArrowBack, MdMoreVert, MdFilterList, MdSearch } from 'react-icons/md';

import {
  FaGenderless,
  FaBriefcase,
  FaBuilding,
  FaUniversity,
  FaDollarSign,
  FaMapMarkerAlt,
} from 'react-icons/fa';

import { ThreeDots } from 'react-loader-spinner'; 

const themeColors = [
  "#DDD8FF",
  "#C2FFE1",
  "#FFE1B1",
  "#FFDBC5",
  "#C5E7FF",
  "#FFDFDC",
  "#D0FFFB",
  "#C5FFE3",
];

const getProviderLogo = (provider) => {
  switch (provider?.toLowerCase()) {
    case 'respondent_io':
      return respondentIoLogo;
    case 'user_interviews':
      return userInterviewsLogo;
    default:
      return null;
  }
};


const getColorById = (id) => {
  const hash = id.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
  const index = hash % themeColors.length;
  return themeColors[index];
};


const Participants = () => {
  const [participants, setParticipants] = useState([]);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [loadingAction, setLoadingAction] = useState(null); // Will store participant ID and action type


  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const { campaignId } = location.state || {};
  const [actionMenuOpen, setActionMenuOpen] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [tempStatuses, setTempStatuses] = useState({});

  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterOptions, setFilterOptions] = useState({});
  const [openFilter, setOpenFilter] = useState(null); // Track which filter dropdown is open

  const [filters, setFilters] = useState({
    qualified: false,
    status: [],
    gender: [],
    occupation: [],
    company: [],
    education_level: [],
    household_income: [],
    location: []
  });
  const menuRef = useRef(null);

  const getParticipantStats = () => {
    const invitedCount = filteredParticipants.filter(p => 
      (tempStatuses[p.id] || p.status) === 'INVITED'
    ).length;
    const attendedCount = filteredParticipants.filter(p => 
      (tempStatuses[p.id] || p.status) === 'COMPLETED'
    ).length;

    return {
      screenerApplied: filteredParticipants.length,
      qualified: filteredParticipants.filter(p => p.qualified).length,
      invited: invitedCount + attendedCount, // Sum of INVITED and COMPLETED
      attendedInterview: attendedCount,
      paid: filteredParticipants.filter(p => 
        (tempStatuses[p.id] || p.status) === 'PAID'
      ).length
    };
  };

  const { getAccessTokenSilently, getIdTokenClaims, isAuthenticated } = useAuth0();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !event.target.closest('.menu-button')
      ) {
        setActionMenuOpen(null);
      }
    };
  
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  

  useEffect(() => {
    if (campaignId && isAuthenticated) {
      fetchParticipants();
    }
  }, [campaignId, isAuthenticated]);

  const fetchParticipants = async () => {
    if (isInitialLoad) setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;

      const response = await fetch(
        `https://dashboard-api.echovane.com/api/campaigns/${campaignId}/participants`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'X-ID-Token': idToken,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        console.log('participants data', data);
        setParticipants(data);
        setFilteredParticipants(data); 
      } else {
        console.error('Failed to fetch participants');
      }
    } catch (error) {
      console.error('Error fetching participants:', error);
    } finally {
      setLoading(false);
      setIsInitialLoad(false); 
    }
  };

  const getActionMenuOptions = (status, participantId) => {
    switch (status) {
      case 'PENDING':
        return [
          { label: 'Invite', action: () => handleInviteParticipant(participantId) },
          { label: 'Mark Completed & Payout', action: () => handleMarkComplete(participantId) },
          { label: 'Report', action: () => handleReportParticipant(participantId) }
        ];
      case 'INVITED':
        return [
          { label: 'Mark Completed & Payout', action: () => handleMarkComplete(participantId) },
          { label: 'Report', action: () => handleReportParticipant(participantId) }
        ];
      case 'COMPLETED':
        return [{ label: 'Report', action: () => handleReportParticipant(participantId) }];
      case 'REPORTED':
        return []; // No options, so we’ll hide the menu
      default:
        return [];
    }
  };


  const toggleActionMenu = (participantId) => {
    setActionMenuOpen(actionMenuOpen === participantId ? null : participantId);
  };
  

  const handleParticipantClick = (participant) => {
    setSelectedParticipant(participant);
  };

  const handleCloseSideSheet = () => {
    setSelectedParticipant(null);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleInviteParticipant = async (participantId) => {
    try {
      setLoadingAction({ id: participantId, action: 'invite' });
      const accessToken = await getAccessTokenSilently();
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;
  
      const response = await fetch(
        `https://dashboard-api.echovane.com/api/participants/${participantId}/invite`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'X-ID-Token': idToken,
          },
        }
      );
  
      if (response.ok) {
        // Re-fetch participants to get updated status from backend
        setTempStatuses((prev) => ({ ...prev, [participantId]: 'INVITED' }));
        await fetchParticipants();
      } else {
        const errorData = await response.json();
        alert(`Failed to invite participant: ${errorData.detail}`);
      }
    } catch (error) {
      console.error('Error inviting participant:', error);
      alert('An error occurred while inviting the participant.');
    } finally {
      setLoadingAction(null); // Reset after API call completes
    }
  };

  const handleMarkComplete = async (participantId) => {
    try {
      setLoadingAction({ id: participantId, action: 'markComplete' });
      const accessToken = await getAccessTokenSilently();
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;
  
      const response = await fetch(
        `https://dashboard-api.echovane.com/api/participants/${participantId}/mark_completed`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'X-ID-Token': idToken,
          },
        }
      );
  
      if (response.ok) {
        setTempStatuses((prev) => ({ ...prev, [participantId]: 'COMPLETED' }));
        await fetchParticipants(); // Refresh participants list
      } else {
        const errorData = await response.json();
        alert(`Failed to mark participant as complete: ${errorData.detail}`);
      }
    } catch (error) {
      console.error('Error marking participant as complete:', error);
    } finally {
      setLoadingAction(null); // Reset loading state
      setActionMenuOpen(null); // Close action menu
    }
  };

  const toggleFilterDropdown = (key) => {
    setOpenFilter(openFilter === key ? null : key);
  };

  const handleFilterChange = (key, value) => {
    setFilters(prev => {
      if (key === 'qualified') {
        return { ...prev, [key]: !prev[key] }; // Toggle boolean
      }
      const currentValues = prev[key];
      if (currentValues.includes(value)) {
        return { ...prev, [key]: currentValues.filter(v => v !== value) };
      } else {
        return { ...prev, [key]: [...currentValues, value] };
      }
    });
  };
  
  
  const handleReportParticipant = async (participantId) => {
    try {
      setLoadingAction({ id: participantId, action: 'report' });
      const accessToken = await getAccessTokenSilently();
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims.__raw;
  
      const response = await fetch(
        `https://dashboard-api.echovane.com/api/participants/${participantId}/report`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'X-ID-Token': idToken,
          },
        }
      );
  
      if (response.ok) {
        setTempStatuses((prev) => ({ ...prev, [participantId]: 'REPORTED' }));
        await fetchParticipants(); // Refresh participants list
      } else {
        const errorData = await response.json();
        alert(`Failed to report participant: ${errorData.detail}`);
      }
    } catch (error) {
      console.error('Error reporting participant:', error);
    } finally {
      setLoadingAction(null); // Reset loading state
      setActionMenuOpen(null); // Close action menu after completion
    }
  };

  useEffect(() => {
    const options = {
      status: [...new Set(participants.map(p => p.status).filter(Boolean))],
      gender: [...new Set(participants.map(p => p.gender).filter(Boolean))],
      occupation: [...new Set(participants.map(p => p.occupation).filter(Boolean))],
      company: [...new Set(participants.map(p => p.company).filter(Boolean))],
      education_level: [...new Set(participants.map(p => p.education_level).filter(Boolean))],
      household_income: [...new Set(participants.map(p => p.household_income).filter(Boolean))],
      location: [...new Set(participants.map(p => [p.city, p.state, p.country].filter(Boolean).join(', ')).filter(Boolean))],
    };
    setFilterOptions(options);
  }, [participants]);

  
  useEffect(() => {
    let result = [...participants];
  
    // Apply search
    if (searchQuery) {
      result = result.filter(p =>
        `${p.first_name} ${p.last_name}`.toLowerCase().includes(searchQuery.toLowerCase()) ||
        p.id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        p.gender?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        p.occupation?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        p.company?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        p.education_level?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        p.household_income?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        [p.city, p.state, p.country].filter(Boolean).join(', ').toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
  
    // Apply filters
    Object.keys(filters).forEach(key => {
      if (key === 'qualified') {
        if (filters[key]) { // If true, filter for qualified: true
          result = result.filter(p => p[key] === true);
        }
      } else if (filters[key].length > 0) {
        result = result.filter(p => {
          const value = key === 'location'
            ? [p.city, p.state, p.country].filter(Boolean).join(', ')
            : p[key];
          return value && filters[key].includes(value);
        });
      }
    });
  
    setFilteredParticipants(result);
  }, [participants, searchQuery, filters]);
  

  return (
    <div className="participants-container">
      {/* Header */}
      <div className="participant-builder-header">
      <div className="back-button-campaign-builder" onClick={handleBack}>
        <img src={cross} className='cross-icon' />
      </div>

      <div className='campaign-name-input'>Respondents </div>

    </div>

<div className="search-filter-container">
  <div className="search-bar">
    <MdSearch className="search-icon" />
    <input
      type="text"
      placeholder="Search by name, ID, or any field..."
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
    />
  </div>

  <div className="filters">
  {Object.keys(filters).map(key => (
    <div key={key} className={key === 'qualified' ? 'filter-toggle' : 'filter-dropdown'}>
      {key === 'qualified' ? (
        <button
          className={`filter-button ${filters[key] ? 'active' : ''}`}
          onClick={() => handleFilterChange(key)}
        >
          <MdFilterList className="filter-icon" />
          Qualified
        </button>
      ) : (
        <>
          <button
            className="filter-button"
            onClick={() => toggleFilterDropdown(key)}
          >
            <MdFilterList className="filter-icon" />
            {key.replace('_', ' ')} {filters[key].length > 0 ? `(${filters[key].length})` : ''}
          </button>
          {openFilter === key && (
            <div className="filter-options">
              {filterOptions[key]?.map(option => (
                <label key={option} className="filter-option">
                  <input
                    type="checkbox"
                    checked={filters[key].includes(option)}
                    onChange={() => handleFilterChange(key, option)}
                  />
                  {option}
                </label>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  ))}
</div>
</div>

<div className="stats-chips-container">
        {[          { label: 'Applied', key: 'screenerApplied', color: '#625DEC' },          { label: 'Qualified', key: 'qualified', color: '#2ECC71' },          { label: 'Invited', key: 'invited', color: '#3498DB' },          { label: 'Attended', key: 'attendedInterview', color: '#F1C40F' },          { label: 'Paid', key: 'paid', color: '#E74C3C' }        ].map((chip) => {
          const stats = getParticipantStats();
          return (
            <div 
              key={chip.key} 
              className="stats-chip"
            >
              <div className="stats-chip-number">
                {stats[chip.key]}
              </div>
              <div className="stats-chip-label">{chip.label}</div>
            </div>
          );
        })}
      </div>

      {isInitialLoad && loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="participants-list">
          {participants.length === 0 ? (
            <div className="recruit-empty-message">
              No respondents have applied for the research yet, please check back later after some
              time.
            </div>
          ) : (
            filteredParticipants.map((participant) => 

              {
                // Determine button text and if the button should be disabled
                let buttonText = 'Invite';
                let isButtonDisabled = false;
                let buttonClass = 'invite-button'; // Default button class
                let status = tempStatuses[participant.id] || participant.status;
                const actionMenuOptions = getActionMenuOptions(status, participant.id);

                // console.log("status value ", status);

                switch (status) {
                  case 'PENDING':
                    buttonText = 'Invite';
                    isButtonDisabled = false;
                    buttonClass = 'pending-button';
                    break;
                  case 'INVITED':
                    buttonText = 'Invited';
                    buttonClass = 'invited-button';
                    isButtonDisabled = true;
                    break;
                  case 'COMPLETED':
                    buttonText = 'Completed';
                    buttonClass = 'completed-button';
                    isButtonDisabled = true;
                    break;
                  case 'NOT COMPLETED':
                    buttonText = 'Not completed';
                    buttonClass = 'not-completed-button';
                    isButtonDisabled = true;
                    break;
                  case 'REPORTED':
                    buttonText = 'Reported';
                    buttonClass = 'reported-button';
                    isButtonDisabled = true;
                    break;
                  default:
                    buttonText = 'OTHER';
                    isButtonDisabled = true;
                    buttonClass = 'disabled-button'; // Black background for disabled buttons
                    break;
                }

                return (
              <div
                key={participant.id}
                className={`participant-card ${
                  selectedParticipant?.id === participant.id ? 'selected' : ''
                }`}
                onClick={() => handleParticipantClick(participant)}
              >
                {/* Invite Button and Applied Date */}
                <div className="card-actions-container">
                <div className="card-actions">
                <button
                    className={buttonClass}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!isButtonDisabled) {
                        handleInviteParticipant(participant.id);
                      }
                    }}
                    disabled={isButtonDisabled || (loadingAction && loadingAction.id === participant.id)}

                  >
                  {loadingAction && loadingAction.id === participant.id ? (
                    <ThreeDots className="three-dots" color="#fff" height={10} width={24} />
                  ) : (
                    buttonText
                  )}

                  </button>

                  {actionMenuOptions.length > 0 && (
                    <div ref={menuRef}  className="more-options">
                      <MdMoreVert
                      className="menu-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleActionMenu(participant.id);
                        }}
                      />
                      {actionMenuOpen === participant.id && (
                        <div className="action-menu-participants">
                          {actionMenuOptions.map((option, index) => (
                            <button
                              key={index}
                              className="action-menu-participants-option"
                              onClick={(e) => {
                                e.stopPropagation();
                                option.action();
                                setActionMenuOpen(null); // Close menu after action
                              }}
                            >
                              {option.label}
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                  {
                    actionMenuOptions.length == 0 && (
                      <div className="more-options">
                      <MdMoreVert
                      className="menu-button"
                        disabled = {true}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleActionMenu(participant.id);
                        }}
                      />
                       </div>
                    )
                  }
                  </div>

                  <div className="applied-date">
                    Applied on {format(new Date(participant.created_at), 'MMM dd, yyyy')}
                  </div>

                  <div className="source-info">
                  Sourced from{' '}
                  <img 
                    src={getProviderLogo(participant.provider_name)} 
                    alt={participant.provider || 'Provider'} 
                    className="provider-logo"
                  />
                </div>
                </div>

                <div className="participant-header">
                  <div className="profile-pic" style={{ backgroundColor: getColorById(participant.id) }}>
                    {participant.first_name.charAt(0)}
                    {participant.last_name.charAt(0)}
                  </div>
                  <div className="participant-name">
                    {participant.first_name} {participant.last_name}
                    {participant.qualified && <span className="qualified-label">Qualified</span>}
                  </div>
                  <div className='profile-id-section'>
                    ID: {participant.id}
                    {participant.linkedin_url && (
                        <a 
                          href={participant.linkedin_url} 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className="linkedin-link"
                        >
                          <FaLinkedin />
                        </a>
                      )}
                    </div>

                </div>

                <div className="profile-info-grid">
                  <div className="info-item">
                    <FaGenderless /> {participant.gender}
                  </div>
                  <div className="info-item">
                    <FaUniversity /> {participant.education_level}
                  </div>
                  <div className="info-item">
                    <FaDollarSign /> {participant.household_income}
                  </div>
                  <div className="info-item">
                    <FaBriefcase /> {participant.occupation}
                  </div>
                  <div className="info-item">
                    <FaBuilding /> {participant.company}
                  </div>
                  <div className="info-item">
                    <FaMapMarkerAlt />{' '}
                    {[participant.city, participant.state, participant.country]
                      .filter(Boolean)
                      .join(', ')}
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    )}

      {selectedParticipant && (
        <div className={`side-sheet ${selectedParticipant ? 'open' : ''}`}>
          <div className="side-sheet-header">
            <h2>Screener Responses</h2>
            <div className="side-sheet-header-close-button" onClick={handleCloseSideSheet}>
            <img src={cross} className='cross-icon' />
          </div>
          </div>
          <div className="screener-responses">
            {selectedParticipant.screener_responses.map((response, index) => (
              <div key={index} className="response-item">
                <strong>{response.question}</strong>
                <p>{response.response}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Participants;
