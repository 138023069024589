// DownloadReport.js
import React from "react";
import { useReactToPrint } from "react-to-print";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faDownload
  } from '@fortawesome/free-solid-svg-icons';

const DownloadReport = ({ contentRef, loading, error }) => {
  // This is the function we call to initiate printing
  const reactToPrintFn = useReactToPrint({
    contentRef,            // Pass the ref per new react-to-print docs
    documentTitle: "CampaignReport",
    pageStyle: `
      /* 1) Force no margin around the page to remove left/right blank space */
      @page {
        margin: 0 !important; /* remove default browser margin */
        size: A4 landscape;
      }

      /* 2) Print-specific rules here */
      @media print {
        /* Remove any default body margin/padding in the print window */
        body {
          margin: 0!important;
          padding: 0!important;
        }

        /* If your main content container has some default padding,
           you can override or reduce it here. Example:
           
           .campaign-report-container {
             padding: 0 !important;
             margin: 0 !important;
           }
        */

        /* Show the footer, pinned bottom-right */
        .print-footer {
          display: block;
          position: fixed;
          bottom: 0;
          right: 0;
          /* tweak these if you want extra spacing from bottom/right edges: */
          margin: 0;
          padding: 10px;

          /* If you need a white background behind the logo, do:
          background: #fff;
          */

          /* If you want to ensure it's not too large:
          max-height: 80px;
          */
        }
      }

      /* 3) On screen: hide the .print-footer so it only appears in PDF */
      @media screen {
        .print-footer {
          display: none !important;
        }
      }
    `,
  });

  return (
    <button onClick={reactToPrintFn} className="download-button" disabled={loading || error}>
          <FontAwesomeIcon icon={faDownload} />
    </button>
  );
};

export default DownloadReport;
