import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { verifyPayment } from "./ApiUtils";
import { useAuth0 } from "@auth0/auth0-react";

const TopUpSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    const verifyTopUpPayment = async () => {
      const params = new URLSearchParams(location.search);
      const sessionId = params.get("session_id");

      if (!sessionId) {
        toast.error("No session ID found.", {
          position: "top-center",
          autoClose: 3000,
          style: { width: "500px" },
        });
        setTimeout(() => navigate("/", { replace: true }), 3000); // Fallback to home
        return;
      }

      try {
        const data = await verifyPayment({
          sessionId,
          getAccessTokenSilently,
          getIdTokenClaims,
        });

        // Determine successPath based on context
        const successPath =
          location.pathname.includes("/research/")
            ? location.pathname.replace("/top-up-success", "")
            : "/";

        if (data.status === "success") {
          toast.success("Top-up successful! Your recruit balance has been updated.", {
            position: "top-center",
            autoClose: 3000,
            style: { width: "500px" },
          });
          setTimeout(() => navigate(successPath, { replace: true }), 3000); // Replace history entry
        } else {
          toast.error("Top-up payment was not successful. Please try again.", {
            position: "top-center",
            autoClose: 3000,
            style: { width: "500px" },
          });
          setTimeout(() => navigate(successPath, { replace: true }), 3000);
        }
      } catch (error) {
        console.error("Error verifying top-up payment:", error);
        toast.error("An error occurred while verifying your top-up.", {
          position: "top-center",
          autoClose: 3000,
          style: { width: "500px" },
        });
        setTimeout(() => navigate("/", { replace: true }), 3000); // Fallback to home
      }
    };

    verifyTopUpPayment();
  }, [location.pathname, location.search, navigate, getAccessTokenSilently, getIdTokenClaims]);

  return <ToastContainer />;
};

export default TopUpSuccess;